/* Definition of all the API Endpoints */ 
export const API_URL = {
  baseUrl: process.env.REACT_APP_API_BASE_URL
    ? process.env.REACT_APP_API_BASE_URL
    : 'https://devapi.ignite.wfglobal.org/api',
  generateQuestions: process.env.REACT_APP_API_BASE_URL
    ? `${process.env.REACT_APP_API_BASE_URL}/questions/generate_questions`
    : 'https://devapi.ignite.wfglobal.org/api/questions/generate_questions',
  relevancyStatusCheck: process.env.REACT_APP_API_BASE_URL
    ? `${process.env.REACT_APP_API_BASE_URL}/relevancy/answer_relevancy_check`
    : 'https://devapi.ignite.wfglobal.org/api/relevancy/answer_relevancy_check',
  followUpQuestion: process.env.REACT_APP_API_BASE_URL
    ? `${process.env.REACT_APP_API_BASE_URL}/questions/generate_followup_questions`
    : 'https://devapi.ignite.wfglobal.org/api/questions/generate_followup_questions',
  suggestionList: process.env.REACT_APP_API_BASE_URL
    ? `${process.env.REACT_APP_API_BASE_URL}/suggestions/suggestion_generation`
    : 'https://devapi.ignite.wfglobal.org/api/suggestions/suggestion_generation',
  evaluation: process.env.REACT_APP_API_BASE_URL
    ? `${process.env.REACT_APP_API_BASE_URL}/evaluate/generate_evaluation`
    : 'https://devapi.ignite.wfglobal.org/api/evaluate/generate_evaluation',
  mentoring_profiling: process.env.REACT_APP_API_BASE_URL
    ? `${process.env.REACT_APP_API_BASE_URL}/mentoring/profiling`
    : 'https://devapi.ignite.wfglobal.org/api/mentoring/profiling',
  mentoring_process: process.env.REACT_APP_API_BASE_URL
    ? `${process.env.REACT_APP_API_BASE_URL}/mentoring/mentoring_process`
    : 'https://devapi.ignite.wfglobal.org/api/mentoring/mentoring_process',
    downloadPDF: process.env.REACT_APP_API_BASE_URL
    ? `${process.env.REACT_APP_API_BASE_URL}/documents/generate_pdf`
    : 'https://devapi.ignite.wfglobal.org/api/documents/generate_pdf',
}
