import React from "react";
import { TextField, Box } from "@mui/material";
import "./ProblemStatement.css";
import { useForm } from "react-hook-form";

/* Component : Problem Statment Form Component Definition */ 
const ProblemStatmentFormComponent = ({
  sendProblemStatementFormData,
  validationError,
  setInputFieldFocused,
  setValidationError,
}) => {
  /* react hook form state */
  const {
    register,
    formState: { errors },
    watch,
  } = useForm();

  /* handle the form onchange event */
  const handleInputChange = () => {
    /*problem Statment Form data  object */
    const formData = {
      who: watch("Who"),
      venture_name: watch("VentureName"),
      why: watch("Why"),
      what: watch("What"),
      how: watch("How"),
      context: watch("Context"),
      customers: watch("Customers"),
      problem: watch("Problem"),
      emotional_impact: watch("EmotionalImpact"),
      alternatives: watch("Alternatives"),
      alternatives_shortcomings: watch("AlternativesShortcomings"),
      question_answer1: watch("QuestionAnswer1"),
      question_answer2: watch("QuestionAnswer2"),
      question_answer3: watch("QuestionAnswer3"),
      question_answer4: watch("QuestionAnswer4"),
      question_answer5: watch("QuestionAnswer5"),
      assumption1: watch("Assumption1"),
      assumption2: watch("Assumption2"),
      assumption3: watch("Assumption3"),
      interview_question1: watch("InterviewQuestion1"),
      interview_question2: watch("InterviewQuestion2"),
      interview_question3: watch("InterviewQuestion3"),
    };
    sendProblemStatementFormData(formData);
  };

  return (
    <form
      className="problemStatmentForm"
      data-testid="problemStatmentForm"
      onChange={handleInputChange}
    >
      <div className="heading">Problem Statement</div>
      <Box className="problemStatmentMainBox">
        <Box>
          <label className="inputFieldLabel">Name of your venture</label>
          <TextField
            size="small"
            name="VentureName"
            {...register("VentureName", { required: true })}
            error={!!errors.VentureName}
            fullWidth
            rows={10}
            className="inputFieldStyle"
            required
            onFocus={() => {
              setInputFieldFocused(true);
              setValidationError("");
            }}
            inputProps={{ "data-testid": "VentureName" }}
          />
          <div>
            <label className="validationErrorMsz">{validationError}</label>
          </div>
          <label className="inputFieldLabel">
            Tell me about your Venture ? (For Example:- E-commerce or Real
            Estate or Financial Services )
          </label>
          <TextField
            size="small"
            name="Who"
            {...register("Who", { required: true })}
            error={!!errors.Who}
            fullWidth
            rows={10}
            className="inputFieldStyle"
            onFocus={() => {
              setInputFieldFocused(true);
              setValidationError("");
            }}
            inputProps={{ "data-testid": "Who" }}
          />
          <div>
            <label className="validationErrorMsz">{validationError}</label>
          </div>

          <label className="inputFieldLabel">
            Explain why you want to pursue this Business Idea?
          </label>
          <TextField
            size="small"
            name="Why"
            {...register("Why", { required: true })}
            error={!!errors.Why}
            fullWidth
            rows={10}
            className="inputFieldStyle"
            onFocus={() => {
              setInputFieldFocused(true);
              setValidationError("");
            }}
            inputProps={{ "data-testid": "Why" }}
          />
          <div>
            <label className="validationErrorMsz">{validationError}</label>
          </div>
          <label className="inputFieldLabel">
            Provide a brief on what your venture does?
          </label>
          <TextField
            inputProps={{ "data-testid": "WhatId" }}
            size="small"
            name="What"
            {...register("What", { required: true })}
            error={!!errors.What}
            fullWidth
            rows={10}
            className="inputFieldStyle"
            onFocus={() => {
              setInputFieldFocused(true);
              setValidationError("");
            }}
          />
          <label className="validationErrorMsz">{validationError}</label>
          <label className="inputFieldLabel">
            Explain how your venture solves the problem and make its revenue?
          </label>
          <TextField
            inputProps={{ "data-testid": "HowId" }}
            size="small"
            name="How"
            {...register("How", { required: true })}
            error={!!errors.How}
            fullWidth
            rows={10}
            className="inputFieldStyle"
            onFocus={() => {
              setInputFieldFocused(true);
              setValidationError("");
            }}
          />
          <div>
            <label className="validationErrorMsz">{validationError}</label>
          </div>
          <label className="inputFieldLabel">
            When does the problem occur?
          </label>
          <TextField
            inputProps={{ "data-testid": "ContextId" }}
            size="small"
            name="Context"
            {...register("Context", { required: true })}
            error={!!errors.Context}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <label className="inputFieldLabel">
            Who has the problem most often?
          </label>
          <TextField
            inputProps={{ "data-testid": "CustomersId" }}
            size="small"
            name="Customers"
            {...register("Customers", { required: true })}
            error={!!errors.Customers}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <label className="inputFieldLabel">
            What is the root cause of the problem?
          </label>
          <TextField
            inputProps={{ "data-testid": "ProblemId" }}
            size="small"
            name="Problem"
            {...register("Problem", { required: true })}
            error={!!errors.Problem}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <label className="inputFieldLabel">How does the customer feel?</label>
          <TextField
            inputProps={{ "data-testid": "EmotionalImpactId" }}
            size="small"
            name="EmotionalImpact"
            {...register("EmotionalImpact", { required: true })}
            error={!!errors.EmotionalImpact}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <label className="inputFieldLabel">
            What do customers do now to ﬁx the problem?
          </label>
          <TextField
            inputProps={{ "data-testid": "AlternativesId" }}
            size="small"
            name="Alternatives"
            {...register("Alternatives", { required: true })}
            error={!!errors.Alternatives}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <label className="inputFieldLabel">
            What are the disadvantages of the alternatives?
          </label>
          <TextField
            inputProps={{ "data-testid": "AlternativesShortcomingsId" }}
            size="small"
            name="AlternativesShortcomings"
            {...register("AlternativesShortcomings", { required: true })}
            error={!!errors.AlternativesShortcomings}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
        </Box>
        <Box>
          <label className="inputFieldLabel">
            Did you target B2B, B2C or both the types of customers in your
            interview?
          </label>
          <TextField
            inputProps={{ "data-testid": "QuestionAnswer1Id" }}
            size="small"
            name="QuestionAnswer1"
            {...register("QuestionAnswer1", { required: true })}
            error={!!errors.QuestionAnswer1}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <label className="inputFieldLabel">
            How many customers did you interview?
          </label>
          <TextField
            inputProps={{ "data-testid": "QuestionAnswer2Id" }}
            size="small"
            type="number"
            name="QuestionAnswer2"
            {...register("QuestionAnswer2", { required: true })}
            error={!!errors.QuestionAnswer2}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />

          <label className="inputFieldLabel">
            What was the mode of interview?
          </label>
          <TextField
            inputProps={{ "data-testid": "QuestionAnswer3Id" }}
            size="small"
            name="QuestionAnswer3"
            {...register("QuestionAnswer3", { required: true })}
            error={!!errors.QuestionAnswer3}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />

          <label className="inputFieldLabel">
            How many of the interviewed customers agreed with you that this is a
            problem and want a solution?
          </label>
          <TextField
            inputProps={{ "data-testid": "QuestionAnswer4Id" }}
            size="small"
            type="number"
            name="QuestionAnswer4"
            {...register("QuestionAnswer4", { required: true })}
            error={!!errors.QuestionAnswer4}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />

          <label className="inputFieldLabel">
            How many of them said they don't need a new solution?
          </label>
          <TextField
            inputProps={{ "data-testid": "QuestionAnswer5Id" }}
            size="small"
            name="QuestionAnswer5"
            type="number"
            {...register("QuestionAnswer5", { required: true })}
            error={!!errors.QuestionAnswer5}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />

          <label className="inputFieldLabel">Assumption 1</label>
          <TextField
            inputProps={{ "data-testid": "Assumption1Id" }}
            size="small"
            name="Assumption1"
            {...register("Assumption1", { required: true })}
            error={!!errors.Assumption1}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <label className="inputFieldLabel">Assumption 2</label>
          <TextField
            inputProps={{ "data-testid": "Assumption2Id" }}
            size="small"
            name="Assumption2"
            {...register("Assumption2", { required: true })}
            error={!!errors.Assumption2}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <label className="inputFieldLabel">Assumption 3</label>
          <TextField
            inputProps={{ "data-testid": "Assumption3Id" }}
            size="small"
            name="Assumption3"
            {...register("Assumption3", { required: true })}
            error={!!errors.Assumption3}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />

          <label className="inputFieldLabel">Interview question 1</label>
          <TextField
            inputProps={{ "data-testid": "InterviewQuestion1Id" }}
            size="small"
            name="InterviewQuestion1"
            {...register("InterviewQuestion1", { required: true })}
            error={!!errors.InterviewQuestion1}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <label className="inputFieldLabel">Interview question 2</label>
          <TextField
            inputProps={{ "data-testid": "InterviewQuestion2Id" }}
            size="small"
            name="InterviewQuestion2"
            {...register("InterviewQuestion2", { required: true })}
            error={!!errors.InterviewQuestion2}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <label className="inputFieldLabel">Interview question 3</label>
          <TextField
            inputProps={{ "data-testid": "InterviewQuestion3Id" }}
            size="small"
            name="InterviewQuestion3"
            {...register("InterviewQuestion3", { required: true })}
            error={!!errors.InterviewQuestion3}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
        </Box>
      </Box>
    </form>
  );
};
export default ProblemStatmentFormComponent;
