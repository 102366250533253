// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputFieldLabel {
  float: left;
  text-align: left;
  margin-bottom: 10px;
}

.heading {
  display: flex;
  justify-content: center;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1.5rem
}

.solutionValueForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 30px;
  padding-left: 30px;
}

.solutionValueMainBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  width: 100%;
}

.inputFieldStyle {
  margin-top: 5px;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/SolutionValue/SolutionValue.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,iBAAiB;EACjB;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,qCAAqC;EACrC,SAAS;EACT,WAAW;AACb;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB","sourcesContent":[".inputFieldLabel {\n  float: left;\n  text-align: left;\n  margin-bottom: 10px;\n}\n\n.heading {\n  display: flex;\n  justify-content: center;\n  font-family: sans-serif;\n  font-weight: bold;\n  font-size: 1.5rem\n}\n\n.solutionValueForm {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding-right: 30px;\n  padding-left: 30px;\n}\n\n.solutionValueMainBox {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 40px;\n  width: 100%;\n}\n\n.inputFieldStyle {\n  margin-top: 5px;\n  margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
