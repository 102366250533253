import React, { useState } from "react";
import {
  TextField,
  Box,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import "./CompetitorsForm.css";
import { useForm } from "react-hook-form";

/* Component : Competitor Form  Definition */ 
const CompetitorsForm = ({
  sendCompetitorAnalysisValueFormData,
  problemStatement,
}) => {
  /* useForm */
  const { register, watch } = useForm();
  /* state to store selected drop down value */
  const [selectedValue, setSelectedValue] = useState("");
  /* state to store competitor form  data */
  const [competitorFormsData, setCompetitorFormsData] = useState([{}]);
  /* state to show comp*/
  const [competitorFormVisible, setCompetitorFormVisible] = useState(false);
  /* state to handle validation message for selectcount and competitor details  */
  const [competitorValidationMsz, setCompetitorValidationMsz] = useState({
    selectCountCompetitor: false,
    provideCompetitorDetails: false,
  });
  /* state to store the Self Venture form data*/
  const [selfVentureFormData, setSelfVentureFormData] = useState({});
  /* set DropDown values and labels */
  const COUNT_COMPETITORS = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
  ];
  /* set self venture name from problemStatement form */
  const defaultVentureValue = problemStatement.venture_name;
  /* set to track venture form fields is filled or not */
  const [isVentureFieldFilled, setIsVentureFieldFilled] = useState(
    Array(selectedValue).fill(false)
  );

  /* function handle the onchange event of checkbox  */
  const handleCheckboxChange = (event) => {
    setCompetitorValidationMsz((prevState) => ({
      ...prevState,
      selectCountCompetitor: true,
    }));
    const isChecked = event.target.checked;
    setCompetitorFormVisible(isChecked);
  };

  /* function handle the onchange event on drop down*/
  const handleDropdownChange = (event) => {
    setCompetitorValidationMsz((prevState) => ({
      ...prevState,
      provideCompetitorDetails: true,
      selectCountCompetitor: false,
    }));
    const count = parseInt(event.target.value, 10);
    setSelectedValue(count);
    // Generate an array of empty objects for each competitor
    const forms = Array.from({ length: count }, () => ({}));
    setCompetitorFormsData(forms);
  };

  /* fucntion onhandle event of competitor form  */
  const handleInputChange = (index) => {
    setCompetitorValidationMsz((prevState) => ({
      ...prevState,
      provideCompetitorDetails: false,
    }));
    // Update the state to indicate that the "venture" field at the given index is filled
    setIsVentureFieldFilled((prevIsVentureFieldFilled) => {
      const updatedIsVentureFieldFilled = [...prevIsVentureFieldFilled];
      // indicates that the "venture" field at that particular form has been filled in.
      updatedIsVentureFieldFilled[index] = true;
      return updatedIsVentureFieldFilled;
    });
    const formData = {
      venture: watch(`venture_${index}`) || "Unanswered",
      benefits: watch(`benefits_${index}`) || "Unanswered",
      product: watch(`product_${index}`) || "Unanswered",
      price: watch(`price_${index}`) || "Unanswered",
      branding_channels: watch(`branding_channels_${index}`) || "Unanswered",
      packaging: watch(`packaging_${index}`) || "Unanswered",
      market_reviews: watch(`market_reviews_${index}`) || "Unanswered",
      UVP: watch(`UVP_${index}`) || "Unanswered",
    };
    const competitorKey = `competitor${index + 2}`;
    const updatedFormData = { [competitorKey]: formData };
    let updatedForms = [...competitorFormsData];
    updatedForms[index] = updatedFormData;
    const finalForm = [...updatedForms];
    setCompetitorFormsData(finalForm);
    /* selfVentureFormData is empty venturename should pass in competitor1 */
    if (Object.keys(selfVentureFormData).length === 0) {
      const finalCompetitorform = [
        { competitor1: { venture: defaultVentureValue } },
        ...updatedForms,
      ];
      const formattedFormData = finalCompetitorform.reduce((result, data) => {
        return { ...result, ...data };
      }, {});

      if (selectedValue > 0) {
        sendCompetitorAnalysisValueFormData(formattedFormData);
      }
    } else {
      const finalCompetitorform = [
        { competitor1: selfVentureFormData },
        ...updatedForms,
      ];
      const formattedFormData = finalCompetitorform.reduce((result, data) => {
        return { ...result, ...data };
      }, {});
      if (
        selectedValue > 0 &&
        !competitorValidationMsz.provideCompetitorDetails
      ) {
        sendCompetitorAnalysisValueFormData(formattedFormData);
      }
    }
  };

  /* function handle the onchange event for self venture form  */
  const handleFormInputChange = (index) => {
    /* solution Value Form data */
    const formData = {
      venture: defaultVentureValue,
      benefits: watch(`benefits`) || "Unanswered",
      product: watch(`product`) || "Unanswered",
      price: watch(`price`) || "Unanswered",
      branding_channels: watch(`branding_channels`) || "Unanswered",
      packaging: watch(`packaging`) || "Unanswered",
      market_reviews: watch(`market_reviews`) || "Unanswered",
      UVP: watch(`UVP`) || "Unanswered",
    };
    setSelfVentureFormData(formData);
  };

  return (
    <Box data-testid="competitorsForm" className="competitorMain">
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{ "data-testid": "custom-checkbox" }}
                checked={competitorFormVisible}
                onChange={handleCheckboxChange}
              />
            }
            label="Competitor Analysis"
          />
        </FormGroup>
      </Box>
      {competitorFormVisible && (
        <>
          <Box data-testid="selectCount">
            <div className="heading">Competitor Analysis</div>
            <Box>
              <label className="inputFieldLabel">
                Select Count of Competitor
              </label>
              <FormControl variant="outlined" fullWidth>
                <Select
                  data-testid="selectId"
                  value={selectedValue}
                  onChange={handleDropdownChange}
                  className="inputFieldStyle"
                >
                  {COUNT_COMPETITORS.map((count) => (
                    <MenuItem
                      data-testid={`listOption-${count.value}`}
                      key={count.value}
                      value={count.value}
                    >
                      {count.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <div className="validationMessage">
              {competitorValidationMsz.provideCompetitorDetails
                ? "Please provide the competitor details"
                : competitorValidationMsz.selectCountCompetitor
                ? "Please select count of competitor"
                : null}
            </div>
          </Box>
          <div className="competitorPage">
            {selectedValue && (
              <Box data-testid="selfVentureForm" key={0} mt={2}>
                <form onChange={() => handleFormInputChange()}>
                  <label className="inputFieldLabel">
                    Name of your venture
                  </label>
                  <TextField
                    inputProps={{ "data-testid": "venture" }}
                    size="small"
                    name={`venture`}
                    {...register(`venture`, { required: true })}
                    fullWidth
                    rows={10}
                    defaultValue={defaultVentureValue || ""} // Set the initial value here
                    disabled
                    className="inputFieldStyle"
                  />

                  <label className="inputFieldLabel">Benefits</label>
                  <TextField
                    inputProps={{ "data-testid": "benefits" }}
                    size="small"
                    name="benefits"
                    {...register("benefits", { required: true })}
                    fullWidth
                    rows={10}
                    className="inputFieldStyle"
                  />
                  <label className="inputFieldLabel">Product</label>

                  <TextField
                    inputProps={{ "data-testid": "product" }}
                    size="small"
                    name="product"
                    {...register("product", { required: true })}
                    fullWidth
                    rows={10}
                    className="inputFieldStyle"
                  />
                  <label className="inputFieldLabel">Price</label>

                  <TextField
                    inputProps={{ "data-testid": "price" }}
                    size="small"
                    name="price"
                    {...register("price", { required: true })}
                    fullWidth
                    rows={10}
                    className="inputFieldStyle"
                  />
                  <label className="inputFieldLabel">Branding channels </label>
                  <TextField
                    inputProps={{ "data-testid": "branding_channels" }}
                    size="small"
                    name="branding_channels"
                    {...register("branding_channels", { required: true })}
                    fullWidth
                    rows={10}
                    className="inputFieldStyle"
                  />
                  <label className="inputFieldLabel">Packaging</label>

                  <TextField
                    inputProps={{ "data-testid": "packaging" }}
                    size="small"
                    name="packaging"
                    {...register("packaging", { required: true })}
                    fullWidth
                    rows={10}
                    className="inputFieldStyle"
                  />
                  <label className="inputFieldLabel">Market reviews </label>
                  <TextField
                    inputProps={{ "data-testid": "market_reviews" }}
                    size="small"
                    name="market_reviews"
                    {...register("market_reviews", { required: true })}
                    fullWidth
                    rows={10}
                    className="inputFieldStyle"
                  />
                  <label className="inputFieldLabel">UVP</label>

                  <TextField
                    inputProps={{ "data-testid": "UVP" }}
                    size="small"
                    name="UVP"
                    {...register("UVP", { required: true })}
                    fullWidth
                    rows={10}
                    className="inputFieldStyle"
                  />
                </form>
              </Box>
            )}

            {competitorFormsData.slice(0, selectedValue).map((form, index) => (
              <Box key={index} mt={2}>
                <form onChange={() => handleInputChange(index)}>
                  <label className="inputFieldLabel">
                    Name of your competitor
                  </label>
                  <TextField
                    inputProps={{ "data-testid": `venture_${index}` }}
                    size="small"
                    name={`venture_${index}`}
                    {...register(`venture_${index}`, { required: true })}
                    helperText={
                      isVentureFieldFilled[index] ? (
                        ""
                      ) : (
                        <span style={{ color: "red" }}>
                          Please enter the name of your competitor
                        </span>
                      )
                    }
                    fullWidth
                    rows={10}
                    className="inputFieldStyle"
                  />
                  <label className="inputFieldLabel">Benefits</label>
                  <TextField
                    inputProps={{ "data-testid": `benefits_${index}` }}
                    size="small"
                    name={`benefits_${index}`}
                    {...register(`benefits_${index}`, { required: true })}
                    fullWidth
                    rows={10}
                    className="inputFieldStyle"
                  />
                  <label className="inputFieldLabel">Product</label>
                  <TextField
                    inputProps={{ "data-testid": `product_${index}` }}
                    size="small"
                    name={`product_${index}`}
                    {...register(`product_${index}`, { required: true })}
                    fullWidth
                    rows={10}
                    className="inputFieldStyle"
                  />
                  <label className="inputFieldLabel">Price</label>
                  <TextField
                    inputProps={{ "data-testid": `price_${index}` }}
                    size="small"
                    name={`price${index}`}
                    {...register(`price_${index}`, { required: true })}
                    fullWidth
                    rows={10}
                    className="inputFieldStyle"
                  />
                  <label className="inputFieldLabel">Branding channels </label>
                  <TextField
                    inputProps={{ "data-testid": `branding_channels_${index}` }}
                    size="small"
                    name={`branding_channels${index}`}
                    {...register(`branding_channels_${index}`, {
                      required: true,
                    })}
                    fullWidth
                    rows={10}
                    className="inputFieldStyle"
                  />
                  <label className="inputFieldLabel">Packaging</label>
                  <TextField
                    inputProps={{ "data-testid": `packaging_${index}` }}
                    size="small"
                    name={`packaging_${index}`}
                    {...register(`packaging_${index}`, { required: true })}
                    fullWidth
                    rows={10}
                    className="inputFieldStyle"
                  />
                  <label className="inputFieldLabel">Market reviews </label>
                  <TextField
                    inputProps={{ "data-testid": `market_reviews_${index}` }}
                    size="small"
                    name={`market_reviews_${index}`}
                    {...register(`market_reviews_${index}`, { required: true })}
                    fullWidth
                    rows={10}
                    className="inputFieldStyle"
                  />
                  <label className="inputFieldLabel">UVP</label>
                  <TextField
                    inputProps={{ "data-testid": `UVP_${index}` }}
                    size="small"
                    name={`UVP_${index}`}
                    {...register(`UVP_${index}`, { required: true })}
                    fullWidth
                    rows={10}
                    className="inputFieldStyle"
                  />
                </form>
              </Box>
            ))}
          </div>
        </>
      )}
    </Box>
  );
};

export default CompetitorsForm;
