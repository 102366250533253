import axios from 'axios'
import { API_URL } from '../Shared/apiEndPointUrl'

/* Handle the suggestion list service */ 
export const makeSuggestionListRequest = (requestBody) => {
  return axios.post(`${API_URL.suggestionList}`, requestBody)
}

/* Handle the Check relevancy status service */ 
export const relevancyStatusCheckSevice = (requestBody) => {
  return axios.post(`${API_URL.relevancyStatusCheck}`, requestBody)
}

/* Handle the follow-up question service */ 
export const followUpQuestionService = (requestBody) => {
  return axios.post(`${API_URL.followUpQuestion}`, requestBody)
}

/* Handle the evaluation service */ 
export const evaluationService = (requestBody) => {
  return axios.post(`${API_URL.evaluation}`, requestBody)
}

/* Handle the  mentoring process service */ 
export const mentoringProcessService = (requestBody) => {
  return axios.post(`${API_URL.mentoring_process}`, requestBody)
}

/* Handle the download PDF Service service */ 
export const downloadPDFService = (requestBody, typeResponse) => {
  return axios.post(`${API_URL.downloadPDF}`, requestBody, typeResponse)
}
