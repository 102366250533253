import React from 'react'
import { Box, Typography } from '@mui/material'

const EvaluationAndMentoring = ({
  mainStatusVisible,
  criteriaVisibleData,
  mentoringData,
}) => {
  return (
    <Box data-testid="evaluationMentoringResponse">
      {[
        mainStatusVisible.evaluateStatusVisible,
        mainStatusVisible.mentoringStatusVisible,
      ].map(
        (isVisible, index) =>
          isVisible && (
            <Box key={index} sx={{ marginTop: '10px' }}>
              <Typography
                variant="h6"
                sx={{ fontWeight: 600, marginBottom: '15px' }}
              >
                {index === 0 ? 'Evaluation' : 'Mentoring'}
              </Typography>
              {(index === 0 ? criteriaVisibleData : mentoringData)
                ?.split('\n')
                .map((line, lineIndex) => (
                  <Box key={lineIndex}>
                    {line.split(':').map((part, idx) => (
                      <Typography key={idx} sx={{ textAlign: 'left' }}>
                        {part}
                      </Typography>
                    ))}
                    <br />
                  </Box>
                ))}
            </Box>
          ),
      )}
    </Box>
  )
}

export default EvaluationAndMentoring
