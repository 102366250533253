import React, { useState } from "react";
import {
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Modal,
  Button,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import "./ChatArea.css";
import { useLocation } from "react-router-dom";
import { promptsList } from "../../assets/json/promptList";
import { messages } from "../../assets/constant/constant";
import EvaluationAndMentoring from "./EvaluationAndMentoring/EvaluationAndMentoring.component";
import QuestionList from "./QuestionList/QuestionList.component";
import CustomPromptModal from "./CustomPromptModal/CustomPromptModal.component";
import {
  skipQuestion,
  evalutionProcess,
  submitAnswers,
  downloadAndExport,
  handleMentoringAPISuccess,
  handleRelevancyAPISuccess,
  handleEvalutionProcessApi,
  relevancyFollowupQus,
} from "./commonFunctions";
import {
  makeSuggestionListRequest,
  relevancyStatusCheckSevice,
  followUpQuestionService,
  evaluationService,
  mentoringProcessService,
  downloadPDFService,
} from "../../Services/ChatArea.service";
import { provideQuestionSuggesstionsExport } from "./suggestionFunction";
/*Component : ChatBot Component Definition */ 
const ChatBot = () => {
  const location = useLocation();
  /*  Access state properties from location.state */
  const transformedData = location.state && location.state.transformedData;
  const questionList = location.state && location.state.questionList;
  const formDataValue = location.state && location.state.formDataValue;
  /* Transformed Question list */
  const generatedQuestionList = questionList?.questions.map((item) => ({
    ...item,
    main_suggestion: [],
    followup: [],
  }));
  /* state to store the list of question*/
  const [questions, setQuestions] = useState(generatedQuestionList);
  /* Current index state for main question */
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  /* Current index state for followup question */
  const [currentFollowUpQuestionIndex] = useState(0);
  /*  main que suggestion list*/
  const [mainSuggestionList, setMainSuggestionList] = useState([]);
  /* State: handles Suggetsion modal open/close */
  const [open, setOpen] = useState(false);
  /* input state for answer */
  const [input, setInput] = useState(null);
  /* set the count for irrelevant answer*/
  const [countIrrelenvant, setCountIrrelevant] = useState({
    mainAnswerIrrelevancy: 0,
    followUpAnswerIrrelevancy: 0,
  });
  /* followUp question visible state */
  const [isFollowUpVisible, setIsFollowUpVisible] = useState(false);
  /* followUp answer visible state */
  const [isFollowUpAnswerVisible, setIsFollowUpAnswerVisible] = useState(false);
  const [isInputFilledModal, setIsInputFilledModal] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  /* state to store */
  const [modalInputFieldValue, setmodalInputFieldValue] = useState("");
  /* swtich the "handleSubmitUserAnswer()" flow */
  const [moveToFollowUpFlow, setMoveToFollowUpFlow] = useState(false);
  /* initialize state to display evaluation and mentoring response */
  const [mainStatusVisible, setMainStatusVisible] = useState({
    evaluateStatusVisible: false,
    mentoringStatusVisible: false,
  });
  /* evaluated criteria display */
  const [criteriaVisibleData, setCriteriaVisibleData] = useState({});
  /* set the input box disabled */
  const [inputFieldDisabled, setInputFieldDisabled] = useState(false);
  /* Initialise the loader and loadertext dynamically  */
  const [loaderVisible, setLoaderVisible] = useState({
    suggestion: { status: false, text: "Providing suggestions" },
    evaluation: { status: false, text: "Providing Evaluation" },
    mentoring: { status: false, text: "Providing Mentoring" },
    download: { status: false, text: "Downloading PDF" },
  });
  /* state to manage the download /mentoring button dynamically */
  const [mainButtonVisible, setMainButtonVisible] = useState({
    mentoring: false,
    download: false,
  });
  /* set the final evaluated json data */
  const [originalData, setOriginaldata] = useState({});
  /*State: handles Cutsom prompt modal open/close */
  const [isModalOpen, setIsModalOpen] = useState(false);
  /* question suggestion visiblity state */
  const [isQuestionSuggestionVisible, setIsQuestionSuggestionVisible] =
    useState(false);
  const [mentoringData, setMentoringData] = useState("");
  /* state to display the relevancy message : irrelevancy */
  const [firstRelevancyCheckMessage, setFirstRelevancyCheckMessage] =
    useState(false);
  /* state to show gpt4 message*/
  const [gpt4Message, setgpt4Message] = useState(false);
  /* state to show the input error message : word should more that 4 words */
  const [inputError, setInputError] = useState("");
  /* modal input value state */
  const [modalInputValue, setModalInputValue] = useState({
    relevancy: "",
    followup: "",
    answerSuggestion: "",
    evaluation: "",
  });
  /* track list of checkBox checked status */
  const [checkStatus, setCheckStatus] = useState({
    relevancy: false,
    followup: false,
    answerSuggestion: false,
    evaluation: false,
  });
  /* Data for the checkboxes */
  const checkboxData = [
    { name: "relevancy", label: "Relevancy Prompt" },
    { name: "followup", label: "Followup Prompt" },
    { name: "answerSuggestion", label: "Suggestion Prompt" },
    { name: "evaluation", label: "Evaluation Prompt" },
  ];
  /* Function handleClose: handle the suggestion modal close event to close the open suggestion modal */
  const handleClose = () => {
    setOpen(false);
  };
  /* Function handleSubmitModalForm : handle modal form submit */
  const handleSubmitModalForm = (event) => {
    event.preventDefault();
    if (selectedCheckbox) {
      /* set the modal input value */
      const updatedModalInputValue = { ...modalInputValue };
      updatedModalInputValue[selectedCheckbox] = modalInputFieldValue.trim();
      /* set checkbox value */
      const updatedCheckStatus = { ...checkStatus };
      updatedCheckStatus[selectedCheckbox] = true;
      /* Update both modalInputValue and checkStatus with new values */
      setModalInputValue(updatedModalInputValue);
      setCheckStatus(updatedCheckStatus);
    }
    setIsModalOpen(false);
  };
  /* Function handleInputChange : Update user answer input field */
  const handleInputChange = (event) => {
    // Update the input value
    setInput(event.target.value);
  };
  /* Function handleModalInputChange:handle the user input of cutsom prompt modal  */
  const handleModalInputChange = (event) => {
    const newValue = event.target.value;
    setIsInputFilledModal(newValue !== "");
    setmodalInputFieldValue(event.target.value);
  };
  /* Function closeModal : Handle the custom prompt modal close event to close the open custom prompt modal */
  const closeModal = () => {
    setIsModalOpen(false);
    setmodalInputFieldValue("");
    setCheckStatus((prevCheckStatus) => ({
      ...prevCheckStatus,
      [selectedCheckbox]: false,
    }));
  };
  /* Function handleCheckboxChange: handle the custom prompt checkbox checked status */
  const handleCheckboxChange = (name) => (event) => {
    const { checked } = event.target;
    setCheckStatus((prevCheckStatus) => ({
      ...prevCheckStatus,
      [name]: checked,
    }));
    setSelectedCheckbox(checked ? name : null);
    if (checked) {
      setmodalInputFieldValue(promptsList[name]);
    } else {
      setmodalInputFieldValue("");
    }
    setIsModalOpen(checked);
  };
   /* Function provideMainQuestionSuggesstions: suggestionList API is called which will give the suggestions for respective question */
  const provideMainQuestionSuggesstions = (event, QAdata) => {
    setLoaderVisible((prevState) => ({
      ...prevState,
      suggestion: { ...prevState.suggestion, status: true },
    }));

    const data = {
      question: isQuestionSuggestionVisible ? QAdata[0] : QAdata,
      user_option: transformedData.venture.milestone1.user_option,
      answerDetails: "I do not know",
      gpt_version: formDataValue.options,
      dynamic_prompt: {
        check: checkStatus.answerSuggestion,
        prompt: modalInputValue.answerSuggestion,
      },
    };
    makeSuggestionListRequest(data)
      .then((response) => {
        provideQuestionSuggesstionsExport(
          setLoaderVisible,
          isQuestionSuggestionVisible,
          currentQuestionIndex,
          questions,
          response,
          currentFollowUpQuestionIndex,
          setQuestions,
          setOpen,
          setMainSuggestionList
        );
      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  /* Function handleSubmitUserAnswer : handling the user input answer conditionally and validation message over inputbox */
  const handleSubmitUserAnswer = () => {
    submitAnswers(
      input,
      setInputError,
      moveToFollowUpFlow,
      questions,
      currentQuestionIndex,
      currentFollowUpQuestionIndex,
      setQuestions,
      setInput,
      setIsFollowUpAnswerVisible,
      relevancyFollowUpAnswerApiCalled,
      relevancyStatusApiCalled
    );
  };
  /*Function relevancyStatusApiCalled: relevancyStatusCheck  API is called to check the answer of mainQuestion relevancy status */
  const relevancyStatusApiCalled = (QAdata) => {
    let data = {
      question: QAdata?.main_question,
      answer: QAdata?.main_answer,
      gpt_version: formDataValue.options,
      dynamic_prompt: {
        check: checkStatus.relevancy,
        prompt: modalInputValue.relevancy,
      },
    };
    relevancyStatusCheckSevice(data)
      .then((response) => {
        handleRelevancyAPISuccess(
          response,
          setQuestions,
          currentQuestionIndex,
          questions,
          setCurrentQuestionIndex,
          followQuestionGenerationAPICalled,
          setCountIrrelevant,
          setIsQuestionSuggestionVisible,
          setInputFieldDisabled,
          setLoaderVisible,
          proceedToEvaluation,
          data,
          countIrrelenvant,
          setFirstRelevancyCheckMessage
        );
      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  /* Function proceedToEvaluation : handling  the logic to transforming the ventureDetails data conditionally after last  question index comes */
  const proceedToEvaluation = () => {
    evalutionProcess(
      questions,
      currentQuestionIndex,
      currentFollowUpQuestionIndex,
      transformedData,
      checkStatus,
      modalInputValue,
      evaluationAPICalled
    );
  };
  /* Function followQuestionGenerationAPICalled:  followUpQuestion API  is called to generate the followup question for respective mainQuestion  */
  const followQuestionGenerationAPICalled = (QAdata) => {
    let data = {
      gpt_version: formDataValue.options,
      ...QAdata,
      dynamic_prompt: {
        check: checkStatus.followup,
        prompt: modalInputValue.followup,
      },
    };
    followUpQuestionService(data).then((response) => {
      const followUpData = response.data;
      const tempQuestions = [...questions];
      /* Push the follow-up question into the followup array */
      tempQuestions[currentQuestionIndex].followup[
        currentFollowUpQuestionIndex
      ] = {
        followup_question: followUpData.followup_question,
        followup_question_id: followUpData.followup_question_id,
      };
      setQuestions(tempQuestions);
      setIsQuestionSuggestionVisible(true);
      /* followup list visible */
      setIsFollowUpVisible(true);
      setMoveToFollowUpFlow(true);
    });
  };
  /* Function relevancyFollowUpAnswerApiCalled : relevancyStatusCheck API is called to check the answer of followupQuestion relevancy status  */
  const relevancyFollowUpAnswerApiCalled = (QAdata) => {
    let data = {
      question:
        QAdata?.followup[currentFollowUpQuestionIndex].followup_question,
      answer: QAdata?.followup[currentFollowUpQuestionIndex].followup_answer,
      gpt_version: formDataValue.options,
      dynamic_prompt: {
        check: checkStatus.relevancy,
        prompt: modalInputValue.relevancy,
      },
    };
    relevancyStatusCheckSevice(data)
      .then((response) => {
        relevancyFollowupQus(
          response,
          questions,
          currentQuestionIndex,
          currentFollowUpQuestionIndex,
          setQuestions,
          setCurrentQuestionIndex,
          setMoveToFollowUpFlow,
          setCountIrrelevant,
          setIsQuestionSuggestionVisible,
          setInputFieldDisabled,
          setLoaderVisible,
          proceedToEvaluation,
          setFirstRelevancyCheckMessage,
          countIrrelenvant
        );
      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  /* Function evaluationAPICalled: Evaluation API is called to start evaluation on venture details and display the evaluation criteria */
  const evaluationAPICalled = (data) => {
    evaluationService(data)
      .then((response) => {
        handleEvalutionProcessApi(
          response,
          toast,
          setMainStatusVisible,
          setCriteriaVisibleData,
          setLoaderVisible,
          setMainButtonVisible,
          setOriginaldata
        );
      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  /* function handleMentoring : handling the conditionally mentoring process over gpt versions */
  const handleMentoring = () => {
    if (formDataValue.options === "gpt-4") {
      setgpt4Message(true);
      mentoringAPICalled(originalData);
    } else {
      setgpt4Message(false);
      mentoringAPICalled(originalData);
      setMainButtonVisible((prevState) => ({
        ...prevState,
        mentoring: false,
      }));
    }
  };
  /* function mentoringAPICalled : mentoring_process API is called to start the mentoring process over the venture details  */
  const mentoringAPICalled = (datas) => {
    setLoaderVisible((prevState) => ({
      ...prevState,
      mentoring: { ...prevState.mentoring, status: true },
    }));
    if (datas) {
      datas.name = formDataValue.name;
    }
    mentoringProcessService(datas)
      .then((response) => {
        handleMentoringAPISuccess(
          response,
          setMentoringData,
          setOriginaldata,
          setMainStatusVisible,
          setLoaderVisible,
          toast
        );
      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  /* function DownloadJSONFile: downloadPDF API is called to download the pdf for venture details */
  const DownloadJSONFile = (originalData) => {
    setLoaderVisible((prevState) => ({
      ...prevState,
      download: { ...prevState.download, status: true },
    }));
    downloadPDFService(originalData, {
      responseType: "arraybuffer", // Ensure binary data is received correctly
    })
      .then((response) => {
        downloadAndExport(response, setLoaderVisible, toast, messages);
      })
      .catch((err) => {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  /* function  handleSkipQuestion : skip question move to next question by incrementing currentQuestionIndex */
  const handleSkipQuestion = () => {
    skipQuestion(
      currentQuestionIndex,
      questions,
      currentFollowUpQuestionIndex,
      setCurrentQuestionIndex,
      setQuestions,
      setIsQuestionSuggestionVisible,
      setMoveToFollowUpFlow,
      setLoaderVisible,
      setInputFieldDisabled,
      proceedToEvaluation
    );
  };
  return (
    <Box className="mainBox">
      <Box data-testid="chatArea">
        <QuestionList
          questions={questions}
          currentQuestionIndex={currentQuestionIndex}
          handleSkipQuestion={handleSkipQuestion}
          provideMainQuestionSuggesstions={provideMainQuestionSuggesstions}
          isFollowUpVisible={isFollowUpVisible}
          isFollowUpAnswerVisible={isFollowUpAnswerVisible}
          mainSuggestionList={mainSuggestionList}
          open={open}
          handleClose={handleClose}
          firstRelevancyCheckMessage={firstRelevancyCheckMessage}
          inputFieldDisabled={inputFieldDisabled}
          input={input}
          inputError={inputError}
          handleInputChange={handleInputChange}
          handleSubmitUserAnswer={handleSubmitUserAnswer}
        />
        {/* dynamic evaluate / mentoring response will displayed  */}
        <EvaluationAndMentoring
          mainStatusVisible={mainStatusVisible}
          criteriaVisibleData={criteriaVisibleData}
          mentoringData={mentoringData}
        />

        {/* dynamic loader displayed  */}
        {Object.values(loaderVisible).some((loader) => loader.status) && (
          <Box
            sx={{ display: "flex", justifyContent: "center", margin: "20px" }}
          >
            <Typography sx={{ marginTop: "7px", marginRight: "10px" }}>
              {
                Object.values(loaderVisible).find((loader) => loader.status)
                  ?.text
              }
            </Typography>
            <CircularProgress />
          </Box>
        )}

        {mainButtonVisible.download && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => DownloadJSONFile(originalData)}
            sx={{ margin: "20px" }}
          >
            DownLoad
          </Button>
        )}

        {mainButtonVisible.mentoring && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleMentoring}
          >
            Mentoring
          </Button>
        )}
        {gpt4Message && (
          <Box>
            <Typography>
              Due to token limit GPT-3.5-turbo-16k is used instead of GPT4
            </Typography>
          </Box>
        )}
      </Box>

      <Box className="check_BoxArea">
        {checkboxData.map((checkbox) => (
          <FormControlLabel
            key={checkbox.name}
            control={
              <Checkbox
                color="success"
                checked={checkStatus[checkbox.name]}
                onChange={handleCheckboxChange(checkbox.name)}
              />
            }
            label={checkbox.label}
          />
        ))}
      </Box>
      <Modal open={isModalOpen} onClose={closeModal}>
        <CustomPromptModal
          handleSubmitModalForm={handleSubmitModalForm}
          handleModalInputChange={handleModalInputChange}
          modalInputFieldValue={modalInputFieldValue}
          isInputFilledModal={isInputFilledModal}
          closeModal={closeModal}
        />
      </Modal>
    </Box>
  );
};
export default ChatBot;
