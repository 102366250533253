import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Toolbar,
  IconButton,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import ChatBotIcon from "../../assets/images/logo3.png";
import { useForm } from "react-hook-form";
import "./Welcome.css";
import { useNavigate } from "react-router-dom";


/* Component : Welcome Component Definiton  */ 
const WelcomeComponent = ({ onAuthentication }) => {
  /* navigate to url */
  const navigate = useNavigate();
  /* state to set the default drop down options */
  const [selectedOption, setSelectedOption] = useState("gpt-3.5-turbo-16k");
  /* list of options for dropt down list */
  const names = [
    { label: "gpt-3.5-turbo", value: "gpt-3.5-turbo-16k" },
    { label: "gpt-4", value: "gpt-4" },
  ];
  /* useform() state  */
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  /* handle form submit function */
  const handleFormSubmit = (data) => {
    /* Authentication true   */
    onAuthentication();
    navigate(`/form`, {
      state: { formDataValue: { ...data, selectedOption } }, // Pass the formDataValue
    });
  };

  return (
    <Box data-testid="welcomeform" className="welcomePage">
      <Box className="welcomeSubDiv">
        <Toolbar className="toolBar">
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img alt="chatbotIcon" src={ChatBotIcon} className="topImg" />
          </IconButton>
          <div className="my-typography-1">ChatBot</div>
        </Toolbar>
        <Box className="loginMainDiv">
          <div className="my-typography-2">Welcome</div>
          <form
            className="welcomForm"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <TextField
              label="Name"
              type="text"
              fullWidth
              error={!!errors.name}
              helperText={!!errors.name && "Fullname is required"}
              {...register("name", { required: true, minLength: 10 })}
              sx={{ marginBottom: "50px" }}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Select</InputLabel>
              <Select
                data-testid="selectId"
                label="Select"
                {...register("options", { required: true })}
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
                fullWidth
                sx={{
                  flex: "none",
                  textAlign: "left",
                  marginBottom: "30px",
                }}
                error={!!errors.options}
              >
                {names.map((name) => (
                  <MenuItem
                    data-testid={`listOption-${name.value}`}
                    key={name.value}
                    value={name.value}
                  >
                    {name.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className="startButton"
            >
              Start Chat
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default WelcomeComponent;
