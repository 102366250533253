import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Avatar,
  Box,
  Typography,
  Modal,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import chatbotIcon from "../../../assets/images/GOlogo.png";
import downArrowIcon from "../../../assets/images/downArrowIcon.png";
import suggestionIcon from "../../../assets/images/suggestionIcon.jpg";
import SendIcon from "@mui/icons-material/Send";

const QuestionList = ({
  questions,
  currentQuestionIndex,
  handleSkipQuestion,
  provideMainQuestionSuggesstions,
  isFollowUpVisible,
  isFollowUpAnswerVisible,
  mainSuggestionList,
  open,
  handleClose,
  firstRelevancyCheckMessage,
  inputFieldDisabled,
  input,
  inputError,
  handleInputChange,
  handleSubmitUserAnswer,
}) => {
  return (
    <>
      <List data-testid="QuestionList">
        {Array.isArray(questions) &&
          questions?.slice(0, currentQuestionIndex + 1).map((item, index) => (
            <React.Fragment key={index}>
              {
                <>
                  <ListItem data-testid={`listItem-${index}`}>
                    <Avatar>
                      <img
                        alt="chatBotIcon"
                        src={chatbotIcon}
                        className="img"
                      />
                    </Avatar>

                    <ListItemText
                      className="listItem"
                      primary={
                        <span>
                          {item.main_question}
                          <Button
                            onClick={() => handleSkipQuestion(item)}
                            disabled={
                              index < currentQuestionIndex ||
                              ["skip", 1, "zero"].includes(
                                item.main_answer_relevancy
                              )
                            }
                            data-testid={`skipMainQuestion-${index}`}
                          >
                            Don't know
                          </Button>
                        </span>
                      }
                    />

                    <Button
                      sx={{ color: "#000", fontWeight: 600 }}
                      onClick={(event) =>
                        index === currentQuestionIndex
                          ? provideMainQuestionSuggesstions(
                              event,

                              item.main_question
                            )
                          : null
                      }
                      data-testid={`mainSuggestion-${index}`}
                      disabled={
                        index < currentQuestionIndex ||
                        ["skip", 1, "zero"].includes(item.main_answer_relevancy)
                      }
                    >
                      Suggestion
                    </Button>
                  </ListItem>
                  <Divider />
                </>
              }
              <>
                {" "}
                <ListItem alignItems="flex-start">
                  <ListItemText
                    data-testid="mainAnswerList"
                    className="listItem"
                    secondary={
                      <span>
                        {item.main_answer}{" "}
                        {index <= currentQuestionIndex &&
                        [0, "zero"].includes(item.main_answer_relevancy) ? (
                          <span style={{ color: "red" }}>Irrelevant</span>
                        ) : null}
                      </span>
                    }
                  />
                </ListItem>
                <Divider />
              </>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box data-testid="suggestionBox" className="sugesstionModal">
                  <div style={{ display: "flex" }}>
                    <Avatar>
                      <img
                        alt="suggestionIcon"
                        src={suggestionIcon}
                        className="img"
                      />
                    </Avatar>
                    <label className="suggestionModalHeading">
                      Suggestions
                    </label>
                  </div>
                  <List>
                    {Array.isArray(mainSuggestionList) &&
                      mainSuggestionList?.map((suggestion, index) => (
                        <ListItem
                          data-testid={`suggestionListItem-${index}`}
                          key={index}
                          className="listItem"
                        >
                          <ListItemText secondary={suggestion} />
                        </ListItem>
                      ))}
                  </List>
                </Box>
              </Modal>

              {isFollowUpVisible &&
                Array.isArray(item.followup) &&
                item?.followup.map((followupItem, followupIndex) => (
                  <React.Fragment key={followupIndex}>
                    <>
                      <ListItem
                        data-testid={`FollowupQuestionList-${followupIndex}`}
                      >
                        <Avatar>
                          <img
                            alt="downArrowIcon"
                            src={downArrowIcon}
                            className="downArrowImg"
                          />
                        </Avatar>
                        &nbsp;
                        <Avatar>
                          <img
                            alt="chatBotIcon"
                            src={chatbotIcon}
                            className="img"
                          />
                        </Avatar>
                        <ListItemText
                          className="listItem"
                          primary={
                            <span>
                              {followupItem.followup_question}
                              <Button
                                onClick={() => {
                                  handleSkipQuestion(followupItem);
                                }}
                                disabled={["skip", 1, "zero"].includes(
                                  followupItem.followup_answer_relevancy
                                )}
                                data-testid={`skipFollowupQuestion-${followupIndex}`}
                              >
                                Don't know
                              </Button>
                            </span>
                          }
                        />
                        <Button
                          data-testid="followupsuggestionBtn"
                          sx={{ color: "#000", fontWeight: 600 }}
                          onClick={(event) =>
                            index === currentQuestionIndex
                              ? provideMainQuestionSuggesstions(
                                  event,
                                  followupItem.followup_question
                                )
                              : null
                          }
                          disabled={["skip", 1, "zero"].includes(
                            followupItem.followup_answer_relevancy
                          )}
                        >
                          Suggestion
                        </Button>
                      </ListItem>
                      <Divider />
                    </>
                    {isFollowUpAnswerVisible && (
                      <>
                        {" "}
                        <ListItem alignItems="flex-start">
                          <ListItemText
                            className="listItem"
                            secondary={
                              <span>
                                {followupItem.followup_answer}{" "}
                                {followupIndex <= currentQuestionIndex &&
                                [0, "zero"].includes(
                                  followupItem.followup_answer_relevancy
                                ) ? (
                                  <span style={{ color: "red" }}>
                                    Irrelevant
                                  </span>
                                ) : null}
                              </span>
                            }
                          />
                        </ListItem>
                        <Divider />
                      </>
                    )}
                  </React.Fragment>
                ))}
            </React.Fragment>
          ))}
      </List>
      <TextField
        fullWidth
        placeholder={
          firstRelevancyCheckMessage
            ? "Please answer again..."
            : "Write Something..."
        }
        inputProps={{ "data-testid": "AnswerInputField" }}
        disabled={inputFieldDisabled}
        value={input}
        error={inputError}
        helperText={inputError ? "Please provide a descriptive answer." : ""}
        onChange={handleInputChange}
        style={{ marginTop: "1rem" }}
        data-testid="AnswerInput"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" cursor="pointer">
              <SendIcon onClick={handleSubmitUserAnswer} cursor="pointer" />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default QuestionList;
