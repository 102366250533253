/* Function for skips questions*/ 
export const skipQuestion = (
  currentQuestionIndex,
  questions,
  currentFollowUpQuestionIndex,
  setCurrentQuestionIndex,
  setQuestions,
  setIsQuestionSuggestionVisible,
  setMoveToFollowUpFlow,
  setLoaderVisible,
  setInputFieldDisabled,
  proceedToEvaluation,
) => {
  if (currentQuestionIndex < questions.length - 1) {
    const tempQuestions = [...questions]
    if (questions[currentQuestionIndex].main_answer_relevancy === 1) {
      tempQuestions[currentQuestionIndex].followup[
        currentFollowUpQuestionIndex
      ].followup_answer_relevancy = 'skip'
      setQuestions(tempQuestions)
      /* increment index*/
      setCurrentQuestionIndex(currentQuestionIndex + 1)
      setMoveToFollowUpFlow(false)
    } else {
      tempQuestions[currentQuestionIndex].main_answer_relevancy = 'skip'
      setQuestions(tempQuestions)
      /* increment index*/
      setCurrentQuestionIndex(currentQuestionIndex + 1)
      setIsQuestionSuggestionVisible(false)
      setMoveToFollowUpFlow(false)
    }
  }
  /* last question skip proceed to evaluate*/
  const lastIndex = questions.length - 1 // Get the last index
  if (questions[currentQuestionIndex] === questions[lastIndex]) {
    const tempQuestions = [...questions]
    if (questions[lastIndex].main_answer_relevancy === 1) {
      tempQuestions[currentQuestionIndex].followup[
        currentFollowUpQuestionIndex
      ].followup_answer_relevancy = 'skip'
      setQuestions(tempQuestions)
    } else {
      tempQuestions[currentQuestionIndex].main_answer_relevancy = 'skip'
      setQuestions(tempQuestions)
    }
    setLoaderVisible((prevState) => ({
      ...prevState,
      evaluation: { ...prevState.evaluation, status: true },
    }))
    setInputFieldDisabled(true)
    proceedToEvaluation()
  }
}
/* Function for Evalution Process */ 
export const evalutionProcess = (
  questions,
  currentQuestionIndex,
  currentFollowUpQuestionIndex,
  transformedData,
  checkStatus,
  modalInputValue,
  evaluationAPICalled,
) => {
  const updatedQuestions = questions.map((item) => {
    if (
      item?.followup.length === 0 &&
      (item.main_answer_relevancy === 'zero' ||
        item.main_answer_relevancy === 'skip')
    ) {
      return {
        ...item,
        main_answer: 'UnAnswered',
      }
    }

    if (
      item.main_answer_relevancy === 1 &&
      (item.followup[0].followup_answer_relevancy === 'skip' ||
        item.followup[0].followup_answer_relevancy === 'zero')
    ) {
      return {
        ...item,
        followup: item.followup.map((followupItem) => ({
          ...followupItem,
          followup_answer: 'UnAnswered',
        })),
      }
    }
    return item
  })
  const lastIndex = updatedQuestions.length - 1 // Get the last index
  if (updatedQuestions[currentQuestionIndex] === updatedQuestions[lastIndex]) {
    const lastQuestion = updatedQuestions[lastIndex]
    const lastMainQuestionValue = lastQuestion.main_answer

    if (
      updatedQuestions[currentQuestionIndex].followup.length > 0 &&
      updatedQuestions[currentQuestionIndex].followup &&
      updatedQuestions[currentQuestionIndex].followup[
        currentFollowUpQuestionIndex
      ] &&
      updatedQuestions[currentQuestionIndex].followup[
        currentFollowUpQuestionIndex
      ].followup_answer
    ) {
      const lastFollowUpQInputValue =
        lastQuestion.followup[currentFollowUpQuestionIndex]?.followup_answer

      if (
        lastFollowUpQInputValue === 'UnAnswered' ||
        updatedQuestions[currentQuestionIndex]?.followup[
          currentFollowUpQuestionIndex
        ].followup_answer ===
          lastQuestion.followup[currentFollowUpQuestionIndex].followup_answer
      ) {
        // Adding new properties and values
        transformedData.venture.milestone1.questions = updatedQuestions
        transformedData.venture.dynamic_prompt = {
          check: checkStatus.evaluation,
          prompt: modalInputValue.evaluation,
        }
        /* evaluation api call */
        evaluationAPICalled(transformedData)
      }
    } else if (updatedQuestions[currentQuestionIndex].followup.length === 0) {
      if (lastMainQuestionValue === 'UnAnswered') {
        // Adding new properties and values
        transformedData.venture.milestone1.questions = updatedQuestions
        transformedData.venture.dynamic_prompt = {
          check: checkStatus.evaluation,
          prompt: modalInputValue.evaluation,
        }
        transformedData.venture.milestone1.mentoring = ''
        /* evaluation api call */
        evaluationAPICalled(transformedData)
      }
    }
  }
}
/* Function for Relevancy Followup Quetions */ 
export const relevancyFollowupQuestions = (
  response,
  currentQuestionIndex,
  questions,
  setQuestions,
  setCurrentQuestionIndex,
  setMoveToFollowUpFlow,
  setCountIrrelevant,
  setIsQuestionSuggestionVisible,
  setInputFieldDisabled,
  setLoaderVisible,
  proceedToEvaluation,
  countIrrelenvant,
  currentFollowUpQuestionIndex,
  setFirstRelevancyCheckMessage,
) => {
  const relevancyStatus = response?.data
  const tempQuestions = [...questions]
  tempQuestions[currentQuestionIndex].followup[
    currentFollowUpQuestionIndex
  ].followup_answer_relevancy = relevancyStatus.isRelevantStatus
  setQuestions(tempQuestions)
  if (
    questions[currentQuestionIndex].followup[currentFollowUpQuestionIndex]
      .followup_answer_relevancy === 1
  ) {
    if (currentQuestionIndex < questions.length - 1) {
      /* increment index*/
      setCurrentQuestionIndex(currentQuestionIndex + 1)
      setMoveToFollowUpFlow(false)
      setCountIrrelevant((prevState) => ({
        ...prevState,
        followUpAnswerIrrelevancy: 0,
      }))
      setIsQuestionSuggestionVisible(false)
    }
    const lastIndex = questions.length - 1 // Get the last index
    if (questions[currentQuestionIndex] === questions[lastIndex]) {
      setInputFieldDisabled(true)
      setLoaderVisible((prevState) => ({
        ...prevState,
        evaluation: { ...prevState.evaluation, status: true },
      }))
      proceedToEvaluation()
    }
  } else if (
    questions[currentQuestionIndex].followup[currentFollowUpQuestionIndex]
      .followup_answer_relevancy === 0
  ) {
    setCountIrrelevant((prevState) => ({
      ...prevState,
      followUpAnswerIrrelevancy: prevState.followUpAnswerIrrelevancy + 1,
    }))
    setFirstRelevancyCheckMessage(true)
    if (countIrrelenvant.followUpAnswerIrrelevancy === 1) {
      tempQuestions[currentQuestionIndex].followup[
        currentFollowUpQuestionIndex
      ].followup_answer_relevancy = 'zero'
      setQuestions(tempQuestions)
      setFirstRelevancyCheckMessage(false)
      if (currentQuestionIndex < questions.length - 1) {
        /* increment index*/
        setCurrentQuestionIndex(currentQuestionIndex + 1)
        setMoveToFollowUpFlow(false)
        setIsQuestionSuggestionVisible(false)
      }
      const lastIndex = questions.length - 1 // Get the last index
      if (
        questions[currentQuestionIndex] === questions[lastIndex] &&
        questions[lastIndex].followup[currentFollowUpQuestionIndex]
          .followup_answer_relevancy === 'zero'
      ) {
        setInputFieldDisabled(true)
        setLoaderVisible((prevState) => ({
          ...prevState,
          evaluation: { ...prevState.evaluation, status: true },
        }))
        proceedToEvaluation()
      }
      /* reset count*/
      setCountIrrelevant((prevState) => ({
        ...prevState,
        followUpAnswerIrrelevancy: 0,
      }))
    }
  }
}
/* Function for Download in Pdf */
export const downloadAndExport = (
  response,
  setLoaderVisible,
  toast,
  messages,
) => {
  if (response.status === 200) {
    const pdfBlob = new Blob([response.data], {
      type: 'application/pdf',
    })
    setLoaderVisible((prevState) => ({
      ...prevState,
      suggestion: { ...prevState.suggestion, status: false },
      evaluation: { ...prevState.evaluation, status: false },
      mentoring: { ...prevState.mentoring, status: false },
      download: { ...prevState.download, status: false },
    }))
    // Create a URL for the PDF blob
    const pdfUrl = URL.createObjectURL(pdfBlob)
    // Trigger the download
    const a = document.createElement('a')
    a.href = pdfUrl
    a.download = 'VentureDetails.pdf'
    a.click()
    toast.success(messages.pdfSuccessMessage, {
      position: toast.POSITION.TOP_RIGHT,
    })
    // Clean up the URL object
    URL.revokeObjectURL(pdfUrl)
  }
}
/* Function for Mentoring process */
export const handleMentoringAPISuccess = (
  response,
  setMentoringData,
  setOriginaldata,
  setMainStatusVisible,
  setLoaderVisible,
  toast,
) => {
  setMentoringData(response.data.mentoring)
  setOriginaldata((prevData) => ({
    ...prevData,
    venture: {
      ...prevData.venture,
      milestone1: {
        ...prevData.venture.milestone1,
        mentoring: response.data.mentoring,
      },
    },
  }))
  setMainStatusVisible((prevState) => ({
    ...prevState,
    mentoringStatusVisible: true,
  }))
  setLoaderVisible((prevState) => ({
    ...prevState,
    suggestion: { ...prevState.suggestion, status: false },
    evaluation: { ...prevState.evaluation, status: false },
    mentoring: { ...prevState.mentoring, status: false },
    download: { ...prevState.download, status: false },
  }))
  toast.success(response?.data.message, {
    position: toast.POSITION.TOP_RIGHT,
  })
}
/* Function for Check relevancy for mainquestions */
export const handleRelevancyAPISuccess = (
  response,
  setQuestions,
  currentQuestionIndex,
  questions,
  setCurrentQuestionIndex,
  followQuestionGenerationAPICalled,
  setCountIrrelevant,
  setIsQuestionSuggestionVisible,
  setInputFieldDisabled,
  setLoaderVisible,
  proceedToEvaluation,
  data,
  countIrrelenvant,
  setFirstRelevancyCheckMessage,
) => {
  const relevancyStatus = response.data
  // adding relevancy status to question array
  const tempQuestions = [...questions]
  /* countIrrelevant(0) set main_answer Provide relevant answer / Refer suggestion */
  tempQuestions[currentQuestionIndex].main_answer_relevancy =
    relevancyStatus.isRelevantStatus
  setQuestions(tempQuestions)
  if (questions[currentQuestionIndex].main_answer_relevancy === 1) {
    /* followup question api call*/
    followQuestionGenerationAPICalled(data)
    setCountIrrelevant((prevState) => ({
      ...prevState,
      mainAnswerIrrelevancy: 0,
    }))
  } else if (questions[currentQuestionIndex].main_answer_relevancy === 0) {
    setCountIrrelevant((prevState) => ({
      ...prevState,
      mainAnswerIrrelevancy: prevState.mainAnswerIrrelevancy + 1,
    }))
    setFirstRelevancyCheckMessage(true)
    if (countIrrelenvant.mainAnswerIrrelevancy === 1) {
      tempQuestions[currentQuestionIndex].main_answer_relevancy = 'zero'
      setQuestions(tempQuestions)
      setFirstRelevancyCheckMessage(false)
      if (currentQuestionIndex < questions.length - 1) {
        /* increment index move to next ques*/
        setCurrentQuestionIndex(currentQuestionIndex + 1)
        setIsQuestionSuggestionVisible(false)
      }
      const lastIndex = questions.length - 1 // Get the last index
      if (
        questions[currentQuestionIndex] === questions[lastIndex] &&
        questions[lastIndex].main_answer_relevancy === 'zero'
      ) {
        setInputFieldDisabled(true)
        setLoaderVisible((prevState) => ({
          ...prevState,
          evaluation: { ...prevState.evaluation, status: true },
        }))
        proceedToEvaluation()
      }
      /* reset count*/
      setCountIrrelevant((prevState) => ({
        ...prevState,
        mainAnswerIrrelevancy: 0,
      }))
    }
  }
}
/* Function for Evalution main api call function */
export const handleEvalutionProcessApi = (
  response,
  toast,
  setMainStatusVisible,
  setCriteriaVisibleData,
  setLoaderVisible,
  setMainButtonVisible,
  setOriginaldata,
) => {
  const evaluationStatus =
    response.data.evaluationResponse.venture.milestone1.evaluation
  toast.success(response?.data.message, {
    position: toast.POSITION.TOP_RIGHT,
  })
  // To update evaluateStatusVisible to true
  setMainStatusVisible((prevState) => ({
    ...prevState,
    evaluateStatusVisible: true,
  }))
  setCriteriaVisibleData(evaluationStatus)
  setLoaderVisible((prevState) => ({
    ...prevState,
    suggestion: { ...prevState.suggestion, status: false },
    evaluation: { ...prevState.evaluation, status: false },
    mentoring: { ...prevState.mentoring, status: false },
    download: { ...prevState.download, status: false },
  }))
  setMainButtonVisible((prevState) => ({
    ...prevState,
    download: true,
  }))
  setOriginaldata(response.data.evaluationResponse)
  const regex = /Overall Average Score: (\d+(\.\d+)?)/
  const overallEvalutionScore = evaluationStatus.match(regex)
  if (overallEvalutionScore[1] > 3) {
    setMainButtonVisible((prevState) => ({
      ...prevState,
      mentoring: true,
    }))
  }
}
/* Function called when users answer submit*/ 
export const submitAnswers = (
  input,
  setInputError,
  moveToFollowUpFlow,
  questions,
  currentQuestionIndex,
  currentFollowUpQuestionIndex,
  setQuestions,
  setInput,
  setIsFollowUpAnswerVisible,
  relevancyFollowUpAnswerApiCalled,
  relevancyStatusApiCalled
) => {
   // Split the input into words based on spaces
   const words = input.trim().split(/\s+/)
   // Check if there are at least 4 words
   if (words.length < 4) {
     setInputError(true)
     return
   } else {
     setInputError(false)
     if (moveToFollowUpFlow) {
       const tempQuestions = [...questions]
       tempQuestions[currentQuestionIndex].followup[
         currentFollowUpQuestionIndex
       ].followup_answer = input
       setQuestions(tempQuestions)
       setInput('')
       setIsFollowUpAnswerVisible(true)
       relevancyFollowUpAnswerApiCalled(questions[currentQuestionIndex])
     } else {
       const tempQuestions = [...questions]
       tempQuestions[currentQuestionIndex].main_answer = input
       setQuestions(tempQuestions)
       setInput('')
       relevancyStatusApiCalled(questions[currentQuestionIndex])
     }
   }
}
/* Function Revancy Check for followup questions*/ 
export const relevancyFollowupQus = (
  response,
  questions,
  currentQuestionIndex,
  currentFollowUpQuestionIndex,
  setQuestions,
  setCurrentQuestionIndex,
  setMoveToFollowUpFlow,
  setCountIrrelevant,
  setIsQuestionSuggestionVisible,
  setInputFieldDisabled,
  setLoaderVisible,
  proceedToEvaluation,
  setFirstRelevancyCheckMessage,
  countIrrelenvant
) => {
  const relevancyStatus = response?.data
  const tempQuestions = [...questions]
  tempQuestions[currentQuestionIndex].followup[
    currentFollowUpQuestionIndex
  ].followup_answer_relevancy = relevancyStatus.isRelevantStatus
  setQuestions(tempQuestions)
  if (
    questions[currentQuestionIndex].followup[currentFollowUpQuestionIndex]
      .followup_answer_relevancy === 1
  ) {
    if (currentQuestionIndex < questions.length - 1) {
      /* increment index*/
      setCurrentQuestionIndex(currentQuestionIndex + 1)
      setMoveToFollowUpFlow(false)
      setCountIrrelevant((prevState) => ({
        ...prevState,
        followUpAnswerIrrelevancy: 0,
      }))
      setIsQuestionSuggestionVisible(false)
    }
    const lastIndex = questions.length - 1 // Get the last index
    if (questions[currentQuestionIndex] === questions[lastIndex]) {
      setInputFieldDisabled(true)
      setLoaderVisible((prevState) => ({
        ...prevState,
        evaluation: { ...prevState.evaluation, status: true },
      }))
      proceedToEvaluation()
    }
  } else if (
    questions[currentQuestionIndex].followup[currentFollowUpQuestionIndex]
      .followup_answer_relevancy === 0
  ) {
    setCountIrrelevant((prevState) => ({
      ...prevState,
      followUpAnswerIrrelevancy: prevState.followUpAnswerIrrelevancy + 1,
    }))
    setFirstRelevancyCheckMessage(true)
    if (countIrrelenvant.followUpAnswerIrrelevancy === 1) {
      tempQuestions[currentQuestionIndex].followup[
        currentFollowUpQuestionIndex
      ].followup_answer_relevancy = 'zero'
      setQuestions(tempQuestions)
      setFirstRelevancyCheckMessage(false)
      if (currentQuestionIndex < questions.length - 1) {
        /* increment index*/
        setCurrentQuestionIndex(currentQuestionIndex + 1)
        setMoveToFollowUpFlow(false)
        setIsQuestionSuggestionVisible(false)
      }
      const lastIndex = questions.length - 1 // Get the last index
      if (
        questions[currentQuestionIndex] === questions[lastIndex] &&
        questions[lastIndex].followup[currentFollowUpQuestionIndex]
          .followup_answer_relevancy === 'zero'
      ) {
        setInputFieldDisabled(true)
        setLoaderVisible((prevState) => ({
          ...prevState,
          evaluation: { ...prevState.evaluation, status: true },
        }))
        proceedToEvaluation()
      }
      /* reset count*/
      setCountIrrelevant((prevState) => ({
        ...prevState,
        followUpAnswerIrrelevancy: 0,
      }))
    }
  }
}