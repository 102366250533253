import React from "react";
import { TextField, Box } from "@mui/material";
import "./SolutionValue.css";
import { useForm } from "react-hook-form";


/* Component : Soltion Value porposition form Component Definition*/ 
const SolutionValue = ({ sendSolutionValueFormData }) => {
  /* react hook form state */
  const {
    register,
    formState: { errors },
    watch,
  } = useForm();

  /* handle form onChange event */
  const handleInputChange = () => {
    /* solution Value Form data */
    const formData = {
      product: watch("product"),
      jobs: watch("jobs"),
      pain_killers: watch("pain_killers"),
      pains: watch("pains"),
      gain_creators: watch("gain_creators"),
      gains: watch("gains"),
      solution: watch("solution"),
      benefits: watch("benefits"),
    };
    /* send the solutionvalue form data to mainform*/
    sendSolutionValueFormData(formData);
  };

  return (
    <form
    data-testid="solutionValueForm"
      className="solutionValueForm"
      onChange={handleInputChange}
    >
      <label className="heading">Solution Value</label>
      <Box className="solutionValueMainBox">
        <div>
          <label className="inputFieldLabel">
            What is the product or service that you are offering?
          </label>
          <TextField
            size="small"
            name="product"
            {...register("product", { required: true })}
            error={!!errors.product}
            fullWidth
            rows={10}
            className="inputFieldStyle"
            inputProps={{ "data-testid": "product" }}
          />
          <label className="inputFieldLabel">
            What would make the customer happy? What do the clients want when
            facing the problem?
          </label>
          <TextField
            size="small"
            name="jobs"
            {...register("jobs", { required: true })}
            error={!!errors.jobs}
            fullWidth
            rows={10}
            className="inputFieldStyle"
            inputProps={{ "data-testid": "jobs" }}
          />
          <label className="inputFieldLabel">
            Which features of your offering relieve the customer's pains?
          </label>
          <TextField
            size="small"
            name="pain_killers"
            {...register("pain_killers", { required: true })}
            error={!!errors.pain_killers}
            fullWidth
            rows={10}
            className="inputFieldStyle"
            inputProps={{ "data-testid": "pain_killers" }}
          />
          <label className="inputFieldLabel">
            What do the clients do (actions) when facing the problem?
          </label>
          <TextField
            size="small"
            name="pains"
            {...register("pains", { required: true })}
            error={!!errors.pains}
            fullWidth
            rows={10}
            className="inputFieldStyle"
            inputProps={{ "data-testid": "pains" }}
          />
          <label className="inputFieldLabel">
            What do you offer that makes the customers happy?
          </label>
          <TextField
            size="small"
            name="gain_creators"
            {...register("gain_creators", { required: true })}
            error={!!errors.gain_creators}
            fullWidth
            rows={10}
            className="inputFieldStyle"
            inputProps={{ "data-testid": "gain_creators" }}
          />
        </div>
        <div>
          <label className="inputFieldLabel">
            What are the pains of the clients when facing the problem?
          </label>
          <TextField
            size="small"
            name="gains"
            {...register("gains", { required: true })}
            error={!!errors.gains}
            fullWidth
            rows={10}
            className="inputFieldStyle"
            inputProps={{ "data-testid": "gains" }}
          />
          <label className="inputFieldLabel">Describe your Solution:</label>
          <TextField
            size="small"
            name="solution"
            {...register("solution", { required: true })}
            error={!!errors.solution}
            fullWidth
            multiline
            rows={6}
            className="inputFieldStyle"
            inputProps={{ "data-testid": "solution" }}
          />
          <label className="inputFieldLabel">
            List the Benefits of Your solutions:
          </label>
          <TextField
            size="small"
            multiline
            name="benefits"
            {...register("benefits", { required: true })}
            error={!!errors.benefits}
            fullWidth
            rows={6}
            className="inputFieldStyle"
            inputProps={{ "data-testid": "benefits" }}
          />
        </div>
      </Box>
    </form>
  );
};
export default SolutionValue;
