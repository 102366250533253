// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topImg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  cursor: pointer;
}

.inputFieldLabel {
  float: left;
  text-align: left;
}

.mainDiv {
  padding: '1rem';
  display: 'grid';
  /* gridTemplateColumns: "1fr"; */
  grid-template-columns: 'repeat(2, 1fr)';
  gap: '40px';
}
.headers {
  background-size: cover;
  background-position: center;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subDiv{
  height: 95vh;
  width: 100%;
  max-width: 126vh;
  border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: 15;
  overflow-y: auto;
}
.my-typography{
  font-weight: 600;
  font-family: "sans-serif";
  font-size: 25px;
  color: white;
}
.tools{
  background-color: #1976d2
}
`, "",{"version":3,"sources":["webpack://./src/Components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,eAAe;EACf,gCAAgC;EAChC,uCAAuC;EACvC,WAAW;AACb;AACA;EACE,sBAAsB;EACtB,2BAA2B;EAC3B,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,2BAA2B;EAC3B,mBAAmB;EACnB,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,eAAe;EACf,YAAY;AACd;AACA;EACE;AACF","sourcesContent":[".topImg {\n  width: 60px;\n  height: 60px;\n  border-radius: 50%;\n  object-fit: cover;\n  margin-right: 15px;\n  cursor: pointer;\n}\n\n.inputFieldLabel {\n  float: left;\n  text-align: left;\n}\n\n.mainDiv {\n  padding: '1rem';\n  display: 'grid';\n  /* gridTemplateColumns: \"1fr\"; */\n  grid-template-columns: 'repeat(2, 1fr)';\n  gap: '40px';\n}\n.headers {\n  background-size: cover;\n  background-position: center;\n  width: 100%;\n  margin-top: 1rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.subDiv{\n  height: 95vh;\n  width: 100%;\n  max-width: 126vh;\n  border: 1px solid lightgray;\n  border-radius: 10px;\n  box-shadow: 15;\n  overflow-y: auto;\n}\n.my-typography{\n  font-weight: 600;\n  font-family: \"sans-serif\";\n  font-size: 25px;\n  color: white;\n}\n.tools{\n  background-color: #1976d2\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
