import "./App.css";
import WelcomeComponent from "./Components/Welcome/Welcome.component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState } from "react";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { MainFormRoute, ChatBotRoute } from "./routeFunction";

function App() {
  // Initialize authentication state as false initially
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Function to handle authentication
  const handleAuthentication = () => {
    // Set isAuthenticated to true if authentication is successful
    setIsAuthenticated(true);
  };
  return (
    <div>
      {" "}
      <ToastContainer />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <WelcomeComponent onAuthentication={handleAuthentication} />
            }
          />
          {/* Protected routes */}
          <Route
            path="/form"
            element={isAuthenticated ? <MainFormRoute /> : <Navigate to="/" />}
          />
          <Route
            path="/chatBot"
            element={isAuthenticated ? <ChatBotRoute /> : <Navigate to="/" />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
