// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submitButton{
  width: 50vh;
  margin: 20px;
  align-items: center;
  height: 45px;
  background-color: #1976d2;
  font-family: sans-serif;
  font-size: 20;
  font-weight: bold;
}

.modalMainBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 800px;
  background-color: #fff;
  box-shadow: 24;
}

.modalForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px;
}

.modalHeading {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 20px;
}

.modalNote {
  font-family: sans-serif;
  color: red;
  font-size: 15px;
  margin-right: 270px;
  margin-bottom: 10px;
}

.modalInput {
  margin-bottom: 15px
}

.modalButton {
  margin-top: 10px;
}

.modalButton button {
  margin-right: 20px
}`, "",{"version":3,"sources":["webpack://./src/Components/MainForm/MainForm.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,yBAAyB;EACzB,uBAAuB;EACvB,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,YAAY;EACZ,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,UAAU;EACV,eAAe;EACf,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE;AACF","sourcesContent":[".submitButton{\n  width: 50vh;\n  margin: 20px;\n  align-items: center;\n  height: 45px;\n  background-color: #1976d2;\n  font-family: sans-serif;\n  font-size: 20;\n  font-weight: bold;\n}\n\n.modalMainBox {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 800px;\n  background-color: #fff;\n  box-shadow: 24;\n}\n\n.modalForm {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin: 30px;\n}\n\n.modalHeading {\n  font-family: sans-serif;\n  font-weight: bold;\n  font-size: 25px;\n  margin-bottom: 20px;\n}\n\n.modalNote {\n  font-family: sans-serif;\n  color: red;\n  font-size: 15px;\n  margin-right: 270px;\n  margin-bottom: 10px;\n}\n\n.modalInput {\n  margin-bottom: 15px\n}\n\n.modalButton {\n  margin-top: 10px;\n}\n\n.modalButton button {\n  margin-right: 20px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
