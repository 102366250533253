
import React from "react";
import HeaderComponent from "./Components/Header/Header.component";
import MainFormComponent from "./Components/MainForm/Mainform.component";
import ChatBot from "./Components/ChatArea/ChatArea.component";

export function MainFormRoute() {
  return (
    <HeaderComponent flag={"main_form"}>
      <MainFormComponent />
    </HeaderComponent>
  );
}

export function ChatBotRoute() {
  return (
    <HeaderComponent flag={"chat_bot"}>
      <ChatBot />
    </HeaderComponent>
  );
}