import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Modal,
  Divider,
  CircularProgress,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { promptsList } from "../../assets/json/promptList";
import SolutionValue from "../SolutionValue/SolutionValue.component";
import "./MainForm.css";
import ProblemStatmentFormComponent from "../ProblemStatement/ProblemStatement.component";
import MarketSegmentation from "../MarketSegmentation/MarketSegmentation.component";
import CompetitorsForm from "../CompetitorsForm/CompetitorsForm.component";
import { messages } from "../../assets/constant/constant";
import {generateQuestionsService} from '../../Services/MainForm.service'


/* Component : Main Form Component Definition */ 
const MainFormComponent = () => {
  const navigate = useNavigate();
  /* State to display validation error according to Child Component */ 
  const [validationError, setValidationError] = useState({
    problemStatementError: "",
    customerMarketError: "",
    competitorError: "",
  });
  const [, setInputFieldFocused] = useState(false);
  /* tranformed data to json structure */
  const [transformed, setTransformed] = useState({});
  /* State to handle the child compoenent data */
  const [formDataFromChild, setFormDataFromChild] = useState({
    problem_statement: {},
    solution_value_proposition: {},
    market_segmentation: {},
    competitor_analysis: {},
  });
  /* uselocstion state */
  const location = useLocation();
  /* Acquire form data */
  const formDataValue = location.state && location.state.formDataValue;
  /* function to recieved the child data */
  const receiveFormDataFromChild = (childData, componentName) => {
    setFormDataFromChild((prevData) => ({
      ...prevData,
      [componentName]: childData,
    }));
  };
  /* state to open modal */
  const [isModalOpen, setIsModalOpen] = useState(false);
  /* state to handle modal save button disabled status */
  const [isModalSaveBtnDisabled, setIsModalSaveBtnDisabled] = useState(true);

  const [saveModalInputValue, setSaveModalInputValue] = useState("");
  /* track list of checkBox checked status */
  const [checkboxStatus, setCheckboxStatus] = useState(false);
  /* modal input value state */
  const [modalInputValue, setModalInputValue] = useState("");
  /* state to display loader */
  const [loader, setLoader] = useState(false);
  /* state to display error message */
  const [errorMessageDisplay, setErrorMessageDisplay] = useState(false);

  /* handle change event for check box*/
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxStatus(checked ? name : null);
    setIsModalOpen(true);
    setIsModalSaveBtnDisabled(true);
  };
  /*  function closes the modal.*/
  const closeModal = () => {
    setIsModalOpen(false);
    let tempCheckboxState = { ...checkboxStatus };
    tempCheckboxState = false;
    setCheckboxStatus(tempCheckboxState);
  };
  /* function updates the state with the input value from a text field within the modal*/
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setModalInputValue(newValue);
    setIsModalSaveBtnDisabled(false);
  };
  /* function handles the submission of the modal form.*/
  const handleSubmitModalForm = (event) => {
    event.preventDefault();
    if (checkboxStatus === "checkbox1") {
      let tempsufficiencyPrompt = saveModalInputValue;
      tempsufficiencyPrompt = modalInputValue;
      setSaveModalInputValue(tempsufficiencyPrompt);
      /* set check box status */
      let tempcheckStatus = { ...checkboxStatus };
      tempcheckStatus = true;

      setCheckboxStatus(tempcheckStatus);
    }
    setIsModalOpen(false);
  };

  /* Function to handle form submission */
  const handleSubmit = () => {
    let currentVenture = {};
    transformed.name = formDataValue?.name;
    transformed.gpt_version = formDataValue?.options;
    transformed.venture = {};
    currentVenture = {
      venture_id: "sampleVentureID123",
      venture_name:
        formDataFromChild.problem_statement.venture_name || "Unanswered",
      milestone1: {
        create_datatime: new Date().toISOString(),
        problem_statement: {
          form_A: {
            why: formDataFromChild.problem_statement.why,
            what: formDataFromChild.problem_statement.what,
            who: formDataFromChild.problem_statement.who,
            how: formDataFromChild.problem_statement.how,
          },
          form_B: {
            context:
              formDataFromChild.problem_statement.context || "Unanswered",
            problem:
              formDataFromChild.problem_statement.problem || "Unanswered",
            alternatives:
              formDataFromChild.problem_statement.alternatives || "Unanswered",
            customers:
              formDataFromChild.problem_statement.customers || "Unanswered",
            emotional_impact:
              formDataFromChild.problem_statement.emotional_impact ||
              "Unanswered",
            alternatives_shortcomings:
              formDataFromChild.problem_statement.alternatives_shortcomings ||
              "Unanswered",
            interview_results: [
              "Did you target B2B, B2C or both the types of customers in your interview? " +
                formDataFromChild.problem_statement.question_answer1 ||
                "Unanswered",
              "How many customers did you interview? " +
                formDataFromChild.problem_statement.question_answer2 ||
                "Unanswered",
              "What was the mode of interview? " +
                formDataFromChild.problem_statement.question_answer3 ||
                "Unanswered",
              "How many of the interviewed customers agreed with you that this is a problem and want a solution? " +
                formDataFromChild.problem_statement.question_answer4 ||
                "Unanswered",
              "How many of them said they don't need a new solution? " +
                formDataFromChild.problem_statement.question_answer5 ||
                "Unanswered",
            ],
            key_assumptions: [
              formDataFromChild.problem_statement.assumption1 || "Unanswered",
              formDataFromChild.problem_statement.assumption2 || "Unanswered",
              formDataFromChild.problem_statement.assumption3 || "Unanswered",
            ],
            key_questions: [
              formDataFromChild.problem_statement.interview_question1 ||
                "Unanswered",
              formDataFromChild.problem_statement.interview_question2 ||
                "Unanswered",
              formDataFromChild.problem_statement.interview_question3 ||
                "Unanswered",
            ],
          },
        },
        customer_market: {
          form_A: {
            ethos: formDataFromChild.market_segmentation.ethos || "Unanswered",
            age: formDataFromChild.market_segmentation.age || "Unanswered",
            gender:
              formDataFromChild.market_segmentation.gender || "Unanswered",
            education_level:
              formDataFromChild.market_segmentation.education_level ||
              "Unanswered",
            occupation:
              formDataFromChild.market_segmentation.occupation || "Unanswered",
            annual_income:
              formDataFromChild.market_segmentation.annual_income ||
              "Unanswered",
            where: formDataFromChild.market_segmentation.where || "Unanswered",
            type_of_location:
              formDataFromChild.market_segmentation.type_of_location ||
              "Unanswered",
            goals: formDataFromChild.market_segmentation.goals || "Unanswered",
            frustration:
              formDataFromChild.market_segmentation.frustration || "Unanswered",
            bio: formDataFromChild.market_segmentation.bio || "Unanswered",
          },
          form_B: {
            TAM: formDataFromChild.market_segmentation.TAM || "Unanswered",
            tam_description:
              formDataFromChild.market_segmentation.tam_description ||
              "Unanswered",
            SAM: formDataFromChild.market_segmentation.SAM || "Unanswered",
            sam_description:
              formDataFromChild.market_segmentation.sam_description ||
              "Unanswered",
            SOM: formDataFromChild.market_segmentation.SOM || "Unanswered",
            som_description:
              formDataFromChild.market_segmentation.som_description ||
              "Unanswered",
            market_size:
              formDataFromChild.market_segmentation.market_size || "Unanswered",
            discribe_your_niche:
              formDataFromChild.market_segmentation.discribe_your_niche ||
              "Unanswered",
          },
        },
        solution_value_proposition: {
          form_A: {
            product:
              formDataFromChild.solution_value_proposition.product ||
              "Unanswered",
            jobs:
              formDataFromChild.solution_value_proposition.jobs || "Unanswered",
            pain_killers:
              formDataFromChild.solution_value_proposition.pain_killers ||
              "Unanswered",
            pains:
              formDataFromChild.solution_value_proposition.pains ||
              "Unanswered",
            gain_creators:
              formDataFromChild.solution_value_proposition.gain_creators ||
              "Unanswered",
            gains:
              formDataFromChild.solution_value_proposition.gains ||
              "Unanswered",
          },
          form_B: {
            solution:
              formDataFromChild.solution_value_proposition.solution ||
              "Unanswered",
            benefits:
              formDataFromChild.solution_value_proposition.benefits ||
              "Unanswered",
          },
        },
        competitor_analysis: formDataFromChild.competitor_analysis,
      },
    };
    transformed.venture = {
      ...currentVenture,
      dynamic_prompt: {
        check: checkboxStatus,
        prompt: saveModalInputValue,
      },
    };
    const requiredFields = ["why", "what", "who", "how"];
    const requiredFieldsCust = ["where"];

    const missingFieldsProblemStatement = requiredFields.filter(
      (field) => !formDataFromChild.problem_statement[field]
    );
    const missingFieldsCustomerMarket = requiredFieldsCust.filter(
      (field) => !formDataFromChild.market_segmentation[field]
    );
    let errorMessage = messages.validationErrorMessage;

    if (missingFieldsProblemStatement.length > 0) {
      setErrorMessageDisplay(true);
      setValidationError({
        problemStatementError: errorMessage,
      });
      toast.error(messages.toasterErrorMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (missingFieldsCustomerMarket.length > 0) {
      setValidationError({
        customerMarketError: errorMessage,
      });
      toast.error(messages.toasterErrorMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setValidationError({
        problemStatementError: "",
        customerMarketError: "",
        competitorError: "",
      });
      setErrorMessageDisplay(false);
      setTransformed(transformed);
      questionsGenerationsApi();
    }
  };

  /* question / sufficiency api called */
  const questionsGenerationsApi = async () => {
    setLoader(true);
    try {
      const response = await generateQuestionsService(transformed)
      const generatedQuestionList = response.data;
      setLoader(false);
      navigate(`/chatbot`, {
        state: {
          formDataValue: formDataValue,
          transformedData: transformed,
          questionList: generatedQuestionList,
        },
      });
    } catch (err) {
      toast.error(err.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Box
      data-testid="mainForm"
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box data-testid="childForm">
        <ProblemStatmentFormComponent
          handleSubmitForm={handleSubmit}
          sendProblemStatementFormData={(data) =>
            receiveFormDataFromChild(data, "problem_statement")
          }
          validationError={validationError.problemStatementError}
          setInputFieldFocused={setInputFieldFocused}
          setValidationError={setValidationError}
        />
        <Divider />

        <MarketSegmentation
          handleSubmitForm={handleSubmit}
          sendMarketSegmentationFormData={(data) =>
            receiveFormDataFromChild(data, "market_segmentation")
          }
          validationError={validationError.customerMarketError}
          setInputFieldFocused={setInputFieldFocused}
          setValidationError={setValidationError}
        />
        <Divider />

        <SolutionValue
          handlehandleSubmitFormSubmit={handleSubmit}
          validationError={validationError.problemStatementError}
          sendSolutionValueFormData={(data) =>
            receiveFormDataFromChild(data, "solution_value_proposition")
          }
        />

        <CompetitorsForm
          problemStatement={formDataFromChild.problem_statement}
          validationError={validationError.competitorError}
          handleSubmitForm={handleSubmit}
          sendCompetitorAnalysisValueFormData={(data) => {
            receiveFormDataFromChild(data, "competitor_analysis");
          }}
        />
      </Box>
      {errorMessageDisplay && (
        <Typography style={{ color: "red" }}>
          Fill the above required fields
        </Typography>
      )}
      <FormControlLabel
        control={
          <Checkbox
            color={"success"}
            checked={checkboxStatus}
            onChange={handleCheckboxChange}
            name="checkbox1"
            inputProps={{ "data-testid": "checkbox" }}
          />
        }
        label="Custom Prompt"
      />
      {loader && (
        <Box sx={{ display: "flex", justifyContent: "center", margin: "20px" }}>
          <CircularProgress />
        </Box>
      )}
      <Button
        className="submitButton"
        type="submit"
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        submit
      </Button>

      <Modal open={isModalOpen} onClose={closeModal}>
        <Box data-testid="customModalId" className="modalMainBox">
          <form className="modalForm" onSubmit={handleSubmitModalForm}>
            <Typography variant="h6" className="modalHeading">
              Custom Prompt
            </Typography>
            <Typography className="modalNote">
              Note : The key name inside the curly brackets {"{}"} should
              remains unchanged
            </Typography>
            <TextField
              inputProps={{ "data-testid": "sufficiency" }}
              defaultValue={promptsList.sufficiency}
              onChange={handleInputChange}
              variant="outlined"
              multiline
              rows={10}
              fullWidth
              className="modalInput"
            />
            <Box className="modalButton">
              <Button
                data-testid="customModalSaveBtn"
                type="submit"
                variant="contained"
                color="primary"
                disabled={isModalSaveBtnDisabled}
              >
                Save
              </Button>
              <Button
                variant="contained"
                data-testid="customModalCancelBtn"
                onClick={closeModal}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};
export default MainFormComponent;
