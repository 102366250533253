import React from "react";
import { Toolbar, Box, IconButton } from "@mui/material";
import "./Header.css";
import chathumanIcon from "../../assets/images/humanIcon.jpg";
import { useLocation } from "react-router-dom";


/* Component : Header Component Definition */ 
const HeaderComponent = ({ children, flag }) => {
  const location = useLocation();
  /* form data value  */
  let formDataValue = "";
  /* condiltional handle formdatavalue : username  */
  if (flag === "main_form" || flag === "chat_bot") {
    formDataValue = location.state && location.state.formDataValue;
  }

  return (
    <Box className="headers" data-testid="headerArea">
      <Box className="subDiv">
        <Toolbar className="tools">
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img alt="chatbotIcon" src={chathumanIcon} className="topImg" />
          </IconButton>
          <div className="my-typography">
            {" "}
            {formDataValue && formDataValue.name
              ? formDataValue.name
              : "Default Header"}
          </div>
        </Toolbar>
        {children}
      </Box>
    </Box>
  );
};

export default HeaderComponent;
