import React from "react";
import { TextField, Box, Select, MenuItem } from "@mui/material";
import "./MarketSegmentation.css";
import { useForm } from "react-hook-form";


/* Component : Market Segmentation Form Component Definition*/ 
const MarketSegmentation = ({
  sendMarketSegmentationFormData,
  validationError,
  setInputFieldFocused,
  setValidationError,
}) => {
  /* react hook form state */
  const {
    register,
    formState: { errors },
    watch,
  } = useForm();
/* list of amount unit*/ 
  const UNIT_OPTIONS = [
    { label: "Thousands", value: "Thousands" },
    { label: "Lakhs", value: "Lakhs" },
    { label: "Crores", value: "Crores" },
  ];
  /* list of Gender options */ 
  const GENDER_OPTIONS = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Both", value: "Both" },
  ];
  /* list of education level options */ 
  const EDUCATION_LEVEL_OPTIONS = [
    { label: "Uneducated", value: "Uneducated" },
    { label: "School Pass", value: "School Pass" },
    { label: "ITI Polytechnic", value: "ITI Polytechnic" },
    { label: "Undergraduate", value: "Undergraduate" },
    { label: "Postgraduate", value: "Postgraduate" },
    { label: "All", value: "All" },
  ];
  /* List of Annual Income ratio */ 
  const ANNUAL_INCOME_OPTIONS = [
    { label: "1-5", value: "1-5" },
    { label: "5-7", value: "5-7" },
    { label: "7-10", value: "7-10" },
    { label: "10+", value: "10+" },
  ];
  /* list of location options */ 
  const TYPE_OF_LOCATION_OPTIONS = [
    { label: "Rural", value: "Rural" },
    { label: "Urban", value: "Urban" },
    { label: "Semi Urban", value: "Semi Urban" },
    { label: "Rural and Semi Urban", value: "Rural and Semi Urban" },
    { label: "Urban and Semi Urban", value: "Urban and Semi Urban" },
    { label: "Rural and Urban", value: "Rural and Urban" },
    { label: "All", value: "All" },
  ];
  /* handle the form onchange event */
  const handleInputChange = () => {
    /* market Segmentation Form  data value */
    const formData = {
      ethos: watch("ethos"),
      age: watch("age"),
      occupation: watch("occupation"),
      where: watch("where"),
      goals: watch("goals"),
      frustration: watch("frustration"),
      bio: watch("bio"),
      TAM: `${watch("TAM")} ${watch("tam_unit")}`,
      SAM: `${watch("SAM")} ${watch("sam_unit")}`,
      SOM: `${watch("SOM")} ${watch("som_unit")}`,
      market_size: watch("market_size"),
      gender: watch("gender"),
      education_level: watch("education_level"),
      annual_income: watch("annual_income"),
      type_of_location: watch("type_of_location"),
      tam_description: watch("tam_description"),
      som_description: watch("som_description"),
      sam_description: watch("sam_description"),
      discribe_your_niche: watch("discribe_your_niche"),
    };
    /* passing marketSegmentation form data to main form component */
    sendMarketSegmentationFormData(formData);
  };

  return (
    <form
      data-testid="marketSegmentationForm"
      className="marketSegmentationForm"
      onChange={handleInputChange}
    >
      <label className="heading">Market segmentation </label>
      <Box className="marketSegmentationMainBox">
        <div>
          <label className="inputFieldLabel">Ethos</label>
          <TextField
            inputProps={{ "data-testid": "ethos" }}
            size="small"
            name="ethos"
            {...register("ethos", { required: true })}
            error={!!errors.ethos}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <label className="inputFieldLabel">Age</label>
          <TextField
            inputProps={{ "data-testid": "age" }}
            size="small"
            name="age"
            type="text"
            {...register("age", { required: true })}
            error={!!errors.age}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <label className="inputFieldLabel">Gender</label>
          <Select
            inputProps={{ "data-testid": "gender" }}
            size="small"
            name="gender"
            {...register("gender", { required: true })}
            error={!!errors.gender}
            fullWidth
            className="inputFieldStyle"
          >
            {GENDER_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <label className="inputFieldLabel">Education Level</label>
          <Select
            inputProps={{ "data-testid": "education_level" }}
            size="small"
            name="education_level"
            {...register("education_level", { required: true })}
            error={!!errors.education_level}
            fullWidth
            className="inputFieldStyle"
          >
            {EDUCATION_LEVEL_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>

          <label className="inputFieldLabel">Occupation</label>
          <TextField
            inputProps={{ "data-testid": "occupation" }}
            size="small"
            name="occupation"
            {...register("occupation", { required: true })}
            error={!!errors.occupation}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />

          <label>Annual Income:</label>
          <div>
            {" "}
            <label className="incomelabel">
              Eg. Salaried employee earning 5-7 Lacs per annum
            </label>
          </div>
          <Select
            size="small"
            name="annual_income"
            inputProps={{ "data-testid": "annual_income" }}
            {...register("annual_income", { required: true })}
            error={!!errors.annual_income}
            fullWidth
            className="inputFieldStyle"
          >
            {ANNUAL_INCOME_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <label className="inputFieldLabel">Location</label>
          <TextField
            inputProps={{ "data-testid": "where" }}
            size="small"
            name="where"
            {...register("where", { required: true })}
            error={!!errors.where}
            fullWidth
            rows={10}
            className="inputFieldStyle"
            onFocus={() => {
              setInputFieldFocused(true);
              setValidationError("");
            }}
          />
          <div>
            <label className="validationErrorMsz">{validationError}</label>
          </div>
          <label className="inputFieldLabel">Type of Location</label>
          <Select
            inputProps={{ "data-testid": "type_of_location" }}
            size="small"
            name="type_of_location"
            {...register("type_of_location", { required: true })}
            error={!!errors.type_of_location}
            fullWidth
            className="inputFieldStyle"
          >
            {TYPE_OF_LOCATION_OPTIONS.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <label className="inputFieldLabel">Goals</label>
          <TextField
            inputProps={{ "data-testid": "goals" }}
            size="small"
            name="goals"
            {...register("goals", { required: true })}
            error={!!errors.goals}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <label className="inputFieldLabel">Frustrations</label>
          <TextField
            inputProps={{ "data-testid": "frustration" }}
            size="small"
            name="frustration"
            {...register("frustration", { required: true })}
            error={!!errors.frustration}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
        </div>
        <div>
          <label className="inputFieldLabel">Bio</label>
          <TextField
            inputProps={{ "data-testid": "bio" }}
            size="small"
            name="bio"
            {...register("bio", { required: true })}
            error={!!errors.bio}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <Box className="inputFieldContainer">
            <label className="inputFieldLabel">TAM</label>
            <Box className="inputFieldFlexContainer">
              <TextField
                inputProps={{ "data-testid": "TAM" }}
                type="number"
                size="small"
                name="TAM"
                {...register("TAM", { required: true })}
                error={!!errors.TAM}
                fullWidth
                rows={10}
                className="inputFieldStyle"
              />
              <Select
                inputProps={{ "data-testid": "tam_unit" }}
                size="small"
                name="tam_unit"
                {...register("tam_unit", { required: true })}
                error={!!errors.tam_unit}
                fullWidth
                className="inputFieldStyle"
              >
                {UNIT_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <label>Provide source of your data:</label>
          <div>
            <label className="incomelabel">
              (Provide links of your sources if any)
            </label>
          </div>

          <TextField
            inputProps={{ "data-testid": "tam_description" }}
            size="small"
            name="tam_description"
            {...register("tam_description", { required: true })}
            error={!!errors.tam_description}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />

          <Box className="inputFieldContainer">
            <label className="inputFieldLabel">SAM</label>
            <Box className="inputFieldFlexContainer">
              <TextField
                inputProps={{ "data-testid": "SAM" }}
                type="number"
                size="small"
                name="SAM"
                {...register("SAM", { required: true })}
                error={!!errors.SAM}
                fullWidth
                rows={10}
                className="inputFieldStyle"
              />
              <Select
                inputProps={{ "data-testid": "sam_unit" }}
                size="small"
                name="sam_unit"
                {...register("sam_unit", { required: true })}
                error={!!errors.sam_unit}
                fullWidth
                className="inputFieldStyle"
              >
                {UNIT_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <label className="inputFieldLabel">
            Provide source of your data:
          </label>
          <TextField
            inputProps={{ "data-testid": "sam_description" }}
            size="small"
            name="sam_description"
            {...register("sam_description", { required: true })}
            error={!!errors.sam_description}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <Box className="inputFieldContainer">
            <label className="inputFieldLabel">SOM</label>
            <Box className="inputFieldFlexContainer">
              <TextField
                inputProps={{ "data-testid": "SOM" }}
                type="number"
                size="small"
                name="SOM"
                {...register("SOM", {
                  required: true,
                })}
                error={!!errors.SOM}
                fullWidth
                rows={10}
                className="inputFieldStyle"
              />
              <Select
                inputProps={{ "data-testid": "som_unit" }}
                size="small"
                name="som_unit"
                {...register("som_unit", { required: true })}
                error={!!errors.som_unit}
                fullWidth
                className="inputFieldStyle"
              >
                {UNIT_OPTIONS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <label className="inputFieldLabel">
            What percentage of the SAM have you used to calculate your SOM?
          </label>
          <TextField
            inputProps={{ "data-testid": "som_description" }}
            size="small"
            name="som_description"
            {...register("som_description", { required: true })}
            error={!!errors.som_description}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <label className="inputFieldLabel">Market Size</label>
          <TextField
            inputProps={{ "data-testid": "market_size" }}
            size="small"
            name="market_size"
            {...register("market_size", { required: true })}
            error={!!errors.market_size}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
          <label className="inputFieldLabel">Describe your Niche</label>
          <TextField
            inputProps={{ "data-testid": "discribe_your_niche" }}
            size="small"
            name="discribe_your_niche"
            {...register("discribe_your_niche", { required: true })}
            error={!!errors.discribe_your_niche}
            fullWidth
            rows={10}
            className="inputFieldStyle"
          />
        </div>
      </Box>
    </form>
  );
};
export default MarketSegmentation;
