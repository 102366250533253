import React from 'react';
import {
    TextField,
    Box,
    Button,
  } from "@mui/material";
  
const CustomPromptModal = ({
  handleSubmitModalForm,
  handleModalInputChange,
  modalInputFieldValue,
  isInputFilledModal,
  closeModal,
}) => {
  return (
    <Box data-testid="cutsomModal" className="modal_Box">
      <form className="modal_Form" onSubmit={handleSubmitModalForm}>
        <label className="modal_Heading">Prompt</label>
        <div>
        <label className="modal_text">
          Note: The key name inside the curly brackets {"{}"} should remain unchanged
        </label>
        </div>
    
        <TextField
          defaultValue={modalInputFieldValue}
          onChange={handleModalInputChange}
          variant="outlined"
          fullWidth
          multiline
          rows={10}
          style={{ marginBottom: "15px" }}
        />
        <div>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isInputFilledModal}
            style={{ marginRight: "20px" }}
          >
            Save
          </Button>
          <Button variant="contained" onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default CustomPromptModal;
