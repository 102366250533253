export const promptsList = {
relevancy: `As an examiner, your task is to assess the relevance of the answer provided to the following question and express this relevance as a percentage:

Question: "{question}"
Answer: "{answer}"

Please note the following:
If the answer given by the user is itself a question, assign a score of 0%.
Ensure that the answer does not merely restate the question or contain verbatim portions of the question.

For the relevancy assessment, consider the following criteria for respective analysis:

a) For the Problem Statement:
- {problem_statement_criteriaA}
- {problem_statement_criteriaB}

b) For Market Segmentation:
- {customer_market_criteriaA}
- {customer_market_criteriaB}

c) For Solution and Value Proposition:
- {value_proposition_criteria}

d) For Competitior Analysis:
- {competitor_analysis_criteria}

Please respond with only the calculated percentage(not other information), and remember to be strict in your assessment of relevancy.

Response must follow below Example: 80%`,

followup: `Considering you are a Business School Faculty, now being an evaluator, you need to ask a follow-up question based on the answer provided by the student to the question below. 

Question = '{question}'   
Answer = '{answer}' 
  
Now you need to ask 1 follow-up question based on the answer given to the above question and considering the criteria mentioned below: 
  
a) Problem Statement criteria:
- {problem_statement_criteriaA}  
- {problem_statement_criteriaB}   
  
b) Market Segmentation criteria:  
- {customer_market_criteriaA}  
- {customer_market_criteriaB}   
  
c) Solution and Value Proposition criteria:  
- {value_proposition_criteria}   
  
d) Competitior Analysis criteria:  
- {competitor_analysis_criteria}   
  
Follow the step-by-step process:   
  1. The response should not contain any extra information.  
  2. The generated questions should be more specific to understand in detail.   
  3. The already answered question should not be repeated. 
  
Example   
  * What does the company do?`,

sufficiency: `As an AI evaluator of an Entrepreneurship program, your task is to generate a set of 2 distinct, specific and relevant questions and do not add numbering strictly to the generated questions for the evaluation on a venture information based on below criteria:  

{component_name}  

[{component_name_criteriaA}  

{component_name_criteriaB}  
]   

To formulate these questions, you must utilize the provided venture information, which is as follows:  

"Venture information:  
{venture_profile}  

Important Notes:  
Ignore Key Assumptions and key questions provided in the venture information while generating questions.  
Completely ignore the missing information and unanswered questions provided in the venture information.
Questions should be specific only to the information provided `,

answerSuggestion: `Considering you are a Business School Faculty, now if you need to provide hints to the question mentioned below on how you expect the answer: 
Question = "{question}"  

Then provide a total of 3 one-liner (like 15 to 20 words) directional suggestions that could help the student to provide relevant answer.  

Important Note:  
The directional suggestions should be based on either of the respective criteria:   

1) {problem_statement_criteriaA} 

2) {problem_statement_criteriaB} 

3) {customer_market_criteriaA} 

4) {customer_market_criteriaB}   

 {value_proposition_criteria} 
 {competitor_analysis_criteria}

`,

evaluation: `As an AI evaluator, your role is to thoroughly examine the student's submitted information for each component. 

Consider 
- Components information: {venture_profile} 
{questions_answers} 

- AI knowledge : {weekly_data_extraction} 
- evaluation criteria: 

{component_name} 

[{component_name_criteriaA} 

{component_name_criteriaB}] 

Key consideration for evaluation: 

- Keep in mind those answers provided by the students in relation to {component_name}. 
- This is the student's first exposure to an entrepreneurship introductory program. 
- Ensure feedback is constructive, encouraging, and promotes growth. 
- Provide feedback on the clarity and organization of the student's submission. 

Evaluate each component's information based on evaluation criteria: 

- **Score**: Rate the student's responses on a numerical scale according to how closely they adhere to the evaluation criteria for {name}, Strictly deduct points for any missing information related to {component_name}. 
- If component's information is not provided for {component_name} then provide score 1. 
- **Strengths**: Highlight the positive aspects based on academic rigor and alignment with the criteria. 
- **Recommendations for Enhancement**: Suggest areas for improvement based on gaps or inaccuracies in the student's responses. 

Note: 
- Do a strict evaluation on the scale of 5 in the scale of 1-5 with whole numbers only. 
- An evaluation is required across five distinct levels of difficulty: 'Very Easy', 'Easy', 'Medium', 'Tough', and 'Very Tough'. The current evaluation difficulty level is set to 'Tough' Please proceed with the evaluation at this level, ensuring a stringent assessment. keeping in mind that 'Very Easy' should be lenient while 'Very Tough' should be highly rigorous. 

Response must follow below format: 
{component_name_response_format}`,
};
