/* Function provideQuestionSuggesstionsExport : Suggestion for main question and followup question logic */ 
export const provideQuestionSuggesstionsExport = (
  setLoaderVisible,
  isQuestionSuggestionVisible,
  currentQuestionIndex,
  questions,
  response,
  currentFollowUpQuestionIndex,
  setQuestions,
  setOpen,
  setMainSuggestionList
) => {
  setLoaderVisible((prevState) => ({
    ...prevState,
    suggestion: { ...prevState.suggestion, status: false },
    evaluation: { ...prevState.evaluation, status: false },
    mentoring: { ...prevState.mentoring, status: false },
  }));
  const suggestionListobj = response.data.suggestedAnswer;
  /* set main ques suggestion list*/
  setMainSuggestionList(suggestionListobj);
  if (isQuestionSuggestionVisible) {
    const tempQuestions = [...questions];
    /* Push the follow-up question into the followup array */
    tempQuestions[currentQuestionIndex].followup[
      currentFollowUpQuestionIndex
    ].followup_suggestions = suggestionListobj;
    setQuestions(tempQuestions);
  } else {
    const tempQuestions = [...questions];
    /* Push the follow-up question into the followup array */
    tempQuestions[currentQuestionIndex].main_suggestion =
      suggestionListobj;
    setQuestions(tempQuestions);
  }
  /* open popover*/
  setOpen(true);
};
