// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topImg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
  cursor: pointer;
}

.my-typography-1 {
  font-weight: 600;
  font-family: "sans-serif";
  font-size: 31px;
  color: white;
}
.welcomePage {
  background-size: cover;
  background-position: center;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.welcomeSubDiv {
  height: 96vh;
  width: 100%;
  max-width: 110vh;
  border: 1px solid lightgray;
  border-radius: 10px;
  box-shadow: 15;
}
.toolBar {
  background-color: #1976d2;
}
.loginMainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 140px;
}
.my-typography-2 {
  font-weight: bold;
  font-size: 42px;
  color: #1976d2;
  margin-bottom: 50px;
  font-family: "sans-serif";
}
.startButton{
  margin-top: 10px;
  font-family: "sans-serif";
  font-weight: bold;
  height: 50px;
  font-size: 16px;
}
.selectOption{
  flex: none;
  text-align: left;
  margin-bottom: 30px;
}
.welcomForm{
  width: 70vh;
}
.nameField{
  margin-Bottom: 30px;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/Components/Welcome/Welcome.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,eAAe;EACf,YAAY;AACd;AACA;EACE,sBAAsB;EACtB,2BAA2B;EAC3B,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,2BAA2B;EAC3B,mBAAmB;EACnB,cAAc;AAChB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,cAAc;EACd,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,iBAAiB;EACjB,YAAY;EACZ,eAAe;AACjB;AACA;EACE,UAAU;EACV,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".topImg {\n  width: 60px;\n  height: 60px;\n  border-radius: 50%;\n  object-fit: cover;\n  margin-right: 15px;\n  cursor: pointer;\n}\n\n.my-typography-1 {\n  font-weight: 600;\n  font-family: \"sans-serif\";\n  font-size: 31px;\n  color: white;\n}\n.welcomePage {\n  background-size: cover;\n  background-position: center;\n  width: 100%;\n  margin-top: 1rem;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.welcomeSubDiv {\n  height: 96vh;\n  width: 100%;\n  max-width: 110vh;\n  border: 1px solid lightgray;\n  border-radius: 10px;\n  box-shadow: 15;\n}\n.toolBar {\n  background-color: #1976d2;\n}\n.loginMainDiv {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding-top: 140px;\n}\n.my-typography-2 {\n  font-weight: bold;\n  font-size: 42px;\n  color: #1976d2;\n  margin-bottom: 50px;\n  font-family: \"sans-serif\";\n}\n.startButton{\n  margin-top: 10px;\n  font-family: \"sans-serif\";\n  font-weight: bold;\n  height: 50px;\n  font-size: 16px;\n}\n.selectOption{\n  flex: none;\n  text-align: left;\n  margin-bottom: 30px;\n}\n.welcomForm{\n  width: 70vh;\n}\n.nameField{\n  margin-Bottom: 30px;\n  color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
